// WeightProjectionGraph.jsx
import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
} from 'recharts';

const WeightProjectionGraph = ({ data, weightUnit }) => {
    // Convert ISO date strings to timestamps for the chart
    const chartData = data.map(point => ({
        ...point,
        date: new Date(point.date).getTime() // Convert ISO date string to timestamp
    }));

    const formatDate = (tick) => {
        const date = new Date(tick);
        const month = date.toLocaleString('default', { month: 'short' });
        const day = date.getDate();
        return `${month} ${day}`;
    };

    const hasBodyFatData = data.some(
        (dp) => dp.bodyFat !== undefined && dp.bodyFat !== null
    );

    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
                <XAxis
                    dataKey="date"
                    tickFormatter={formatDate}
                    type="number"
                    domain={['dataMin', 'dataMax']}
                />
                <YAxis
                    yAxisId="left"
                    orientation="left"
                    label={{ value: 'Weight', angle: -90, position: 'insideLeft' }}
                />
                {hasBodyFatData && (
                    <YAxis
                        yAxisId="right"
                        orientation="right"
                        label={{ value: 'Body Fat %', angle: 90, position: 'insideRight' }}
                    />
                )}
                <Tooltip
                    labelFormatter={formatDate}
                    formatter={(value, name) => {
                        const unit = name === 'Weight' ? weightUnit || 'lbs' : '%';
                        return [`${value.toFixed(1)} ${unit}`, name];
                    }}
                />
                <Legend />
                <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="weight"
                    stroke="#8884d8"
                    dot={false}
                    name="Weight"
                />
                {hasBodyFatData && (
                    <Line
                        yAxisId="right"
                        type="monotone"
                        dataKey="bodyFat"
                        stroke="#82ca9d"
                        dot={false}
                        name="Body Fat %"
                    />
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default WeightProjectionGraph;
