import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth_context';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import { groupDataByWeek, aggregateWeeklyData } from 'helpers/data_helpers';
import { getMealDateString } from 'contexts/cart_context';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Brush,
} from 'recharts';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const CaloriesOverTimeGraph = () => {
    const { currentUser } = useContext(AuthContext);
    const [weeklyData, setWeeklyData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWeeklyData = async () => {
            try {
                const db = getFirestore();
                const calendarMealCollectionRef = collection(
                    db,
                    'users',
                    currentUser.uid,
                    'calendarMeals'
                );

                const timeFrame = new Date();
                timeFrame.setDate(timeFrame.getDate() - 180);

                // Query for both mealDate and calendarDate
                const timeFrameStr = getMealDateString({ calendarDate: timeFrame });
                
                // Need to query for both old and new date formats
                const mealsQuery = query(
                    calendarMealCollectionRef,
                    where('calendarDate', '>', timeFrame)
                );

                const querySnapshot = await getDocs(mealsQuery);

                let data = [];

                querySnapshot.forEach((doc) => {
                    const tempDoc = doc.data();
                    
                    // Handle both date formats
                    let localDate;
                    if (tempDoc.mealDate) {
                        // Parse mealDate string
                        const [year, month, day] = tempDoc.mealDate.split('-').map(Number);
                        localDate = new Date(year, month - 1, day);
                    } else if (tempDoc.calendarDate) {
                        // Handle legacy calendarDate (which is a Firestore timestamp)
                        const timestamp = tempDoc.calendarDate.seconds * 1000;
                        const date = new Date(timestamp);
                        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                        localDate = new Date(date.getTime() - userTimezoneOffset);
                    }

                    if (localDate) {
                        const totalCalories = parseFloat(getMealNutrientAmount(tempDoc, 'Calories')) || 0;
                        const proteinGrams = parseFloat(getMealNutrientAmount(tempDoc, 'Protein')) || 0;
                        const carbsGrams = parseFloat(getMealNutrientAmount(tempDoc, 'Carbohydrates')) || 0;
                        const fatGrams = parseFloat(getMealNutrientAmount(tempDoc, 'Fat')) || 0;

                        data.push({
                            date: localDate,
                            totalCalories,
                            proteinGrams,
                            carbsGrams,
                            fatGrams
                        });
                    }
                });

                // Group data by week
                const groupedData = groupDataByWeek(data);

                // Aggregate data per week
                let aggregatedData = [];

                for (let weekDate in groupedData) {
                    const weekData = groupedData[weekDate];
                    
                    // First group by day
                    const dailyTotals = {};
                    
                    weekData.forEach(meal => {
                        const dayKey = getMealDateString({ calendarDate: meal.date });
                        if (!dailyTotals[dayKey]) {
                            dailyTotals[dayKey] = {
                                totalCalories: 0,
                                proteinGrams: 0,
                                carbsGrams: 0,
                                fatGrams: 0
                            };
                        }
                        dailyTotals[dayKey].totalCalories += meal.totalCalories;
                        dailyTotals[dayKey].proteinGrams += meal.proteinGrams;
                        dailyTotals[dayKey].carbsGrams += meal.carbsGrams;
                        dailyTotals[dayKey].fatGrams += meal.fatGrams;
                    });

                    // Now count days with meaningful data (e.g., more than 100 calories)
                    const daysWithData = Object.values(dailyTotals)
                        .filter(day => day.totalCalories > 100).length;

                    // Calculate weekly averages
                    const totals = Object.values(dailyTotals).reduce((acc, day) => ({
                        totalCalories: acc.totalCalories + day.totalCalories,
                        proteinGrams: acc.proteinGrams + day.proteinGrams,
                        carbsGrams: acc.carbsGrams + day.carbsGrams,
                        fatGrams: acc.fatGrams + day.fatGrams
                    }), { totalCalories: 0, proteinGrams: 0, carbsGrams: 0, fatGrams: 0 });

                    aggregatedData.push({
                        weekDate: weekDate,
                        totalCalories: daysWithData > 0 ? totals.totalCalories / daysWithData : 0,
                        proteinGrams: daysWithData > 0 ? totals.proteinGrams / daysWithData : 0,
                        carbsGrams: daysWithData > 0 ? totals.carbsGrams / daysWithData : 0,
                        fatGrams: daysWithData > 0 ? totals.fatGrams / daysWithData : 0
                    });
                }

                // Log to Firebase Analytics
                FirebaseAnalytics.logEvent({
                    name: 'saw_macros_graph',
                });

                // Modify week labels
                aggregatedData = aggregatedData.map((week) => {
                    const date = new Date(week.weekDate);
                    const formattedDate = date.toLocaleDateString('en-US', { 
                        month: 'numeric', 
                        day: 'numeric',
                        timeZone: 'UTC'
                    });
                    
                    return {
                        ...week,
                        weekLabel: `Week of ${formattedDate}`,
                    };
                });

                // Sort the data by week date
                aggregatedData.sort((a, b) => new Date(a.weekDate) - new Date(b.weekDate));

                setWeeklyData(aggregatedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching weekly data:', error);
                setLoading(false);
            }
        };

        fetchWeeklyData();
    }, [currentUser.uid]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{
                    backgroundColor: '#fff',
                    padding: '10px',
                    border: '1px solid #ccc'
                }}>
                    <p className="label">
                        <strong>{label}</strong>
                    </p>
                    <p>Avg Daily Calories: {payload[0].payload.totalCalories.toLocaleString()}</p>
                    <div style={{ color: '#24C581' }}>
                        Avg Daily Protein: {payload[0].payload.proteinGrams.toFixed(1)}g
                    </div>
                    <div style={{ color: '#25ABFF', margin:'5px 0px' }}>
                        Avg Daily Carbs: {payload[0].payload.carbsGrams.toFixed(1)}g
                    </div>
                    <div style={{ color: '#FF802C' }}>
                        Avg Daily Fat: {payload[0].payload.fatGrams.toFixed(1)}g
                    </div>
                </div>
            );
        }
        return null;
    };

    const renderChart = () => {
        if (loading) {
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h2>Loading data...</h2>
                </div>
            );
        }

        if (weeklyData.length === 0) {
            return (
                <div style={{ textAlign: 'center', marginTop: '50px' }}>
                    <h2>No data available for the selected period.</h2>
                    <p>Please track your meals to see weekly reports.</p>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={weeklyData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="proteinGrams"
                        name="Protein (g)"
                        stackId="1"
                        stroke="#24C581"
                        fill="#24C581"
                    />
                    <Area
                        type="monotone"
                        dataKey="carbsGrams"
                        name="Carbs (g)"
                        stackId="1"
                        stroke="#25ABFF"
                        fill="#25ABFF"
                    />
                    <Area
                        type="monotone"
                        dataKey="fatGrams"
                        name="Fat (g)"
                        stackId="1"
                        stroke="#FF802C"
                        fill="#FF802C"
                    />
                    <Brush
                        dataKey="weekLabel"
                        height={30}
                        stroke="#2D7933"
                        startIndex={Math.max(weeklyData.length - 4, 0)}
                        endIndex={weeklyData.length - 1}
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div
            className="weekly-report-page"
            style={{
                maxWidth: '800px',
                margin: '0 auto',
                marginLeft: '-10px',
                width: '100%',
            }}
        >
            {renderChart()}
            <div style={{ marginTop: '10px', fontSize: '12px', color: 'grey', marginLeft: '15px', textAlign: 'center' }}>
                Note: it's better to skip whole days than to log partial days
            </div>
        </div>
    );
};

export default CaloriesOverTimeGraph;