import React from 'react';
import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import { groupDataByWeek } from 'helpers/data_helpers';

const StepsOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject;
    let data = [];

    if (userObject.stepData && Object.keys(userObject.stepData).length > 0) {
        for (let dateStr of Object.keys(userObject.stepData)) {
            const date = parseDateMonthYear(dateStr);
            const steps = userObject.stepData[dateStr];

            if (steps > 0) {
                data.push({
                    date: date,
                    steps: steps
                });
            }
        }

        // Group data by week
        const groupedData = groupDataByWeek(data);

        // Aggregate data per week
        let aggregatedData = [];

        for (let weekDate in groupedData) {
            const weekData = groupedData[weekDate];
            const totalSteps = weekData.reduce((sum, item) => sum + item.steps, 0);
            const daysWithData = weekData.length;
            const avgSteps = totalSteps / daysWithData;

            aggregatedData.push({
                weekDate: weekDate,  // Store the full date
                avgSteps: avgSteps
            });
        }

        // Modify week labels
        aggregatedData = aggregatedData.map((week) => {
            const date = new Date(week.weekDate);
            
            // Format Monday's date
            const formattedDate = date.toLocaleDateString('en-US', { 
                month: 'numeric', 
                day: 'numeric',
                timeZone: 'UTC' // Add this to prevent timezone issues
            });
            
            return {
                ...week,
                weekLabel: `Week of ${formattedDate}`,
            };
        });

        // Sort the data by week date
        aggregatedData.sort((a, b) => new Date(a.weekDate) - new Date(b.weekDate));

        return (
            <ResponsiveContainer style={{marginLeft:'-20px'}} width="100%" height={300}>
                <AreaChart data={aggregatedData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip formatter={(value) => `${value.toFixed(0)} steps`} />
                    <Area
                        type="monotone"
                        dataKey="avgSteps"
                        name="Avg Steps"
                        stroke="#f1a092"
                        fill="rgba(241, 160, 146, 0.2)"
                    />
                    <Brush dataKey="weekLabel" height={30} stroke="#f1a092" />
                </AreaChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h2>No step data found.</h2>
                <p>Please log your steps to see data.</p>
            </div>
        );
    }
};

export default StepsOverTimeGraph; 