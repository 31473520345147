import './modify_calories.css';

//React, router
import React from 'react';


//context


import CalorieBoxBreakdown from './calorie_box_breakdown';
import CalorieBox from './calorie_box';
import { Tab, Tabs } from '@material-ui/core';

const ModifyCalories = (props) => {
    const [tabValue, setTabValue] = React.useState(props.tab ? props.tab : 0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    };

    //user is inputting macros
    return (<div style={{ marginTop: '27px' }}><CalorieBox close={props.close} /></div>
    );


}
export default ModifyCalories