import React from 'react';
import {
    TextField,
    InputAdornment,
    Typography,
    Slider,
    withStyles,
} from '@material-ui/core';
import { calculateProjections } from '../projection_calculations';
import WeightProjectionGraph from '../weight_projection_graph';
import MacroSlider from '../macro_slider';
import './goal_setting_step.css';
import { UserContext } from 'contexts/user_context';

const GreenSlider = withStyles({
    root: {
        color: 'rgb(76, 184, 133)',
    },
    valueLabel: {
        '& *': {
            background: 'rgb(76, 184, 133)',
            color: 'white',
        },
    },
})(Slider);

const StyledTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'rgb(76, 184, 133)',
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'rgb(76, 184, 133)',
        },
    },
})(TextField);

const GoalSettingStep = ({ 
    formData, 
    onUpdate, 
    userObject, 
    setUserObject, 
    needsCalculation,
    setNeedsCalculation,
    errors,
    dailyCalories,
    macroDistribution,
    weightProjection 
}) => {
    const [projections, setProjections] = React.useState(null);
    const [recommendations, setRecommendations] = React.useState(null);
    const getCurrentWeight = React.useContext(UserContext).getCurrentWeight;

    React.useEffect(() => {
        if (formData.goalWeight || formData.goalBodyFat) {
            setNeedsCalculation(true);
            setProjections(null);
            setRecommendations(null);
        }
    }, [formData.goalWeight, formData.goalBodyFat]);

    // Update projections when calculations are completed
    React.useEffect(() => {
        if (!needsCalculation && weightProjection) {
            setProjections({
                dataPoints: weightProjection,
                warning: [], // These could be passed from parent if needed
                recommendations: ''
            });
            generateRecommendations();
        }
    }, [needsCalculation, weightProjection]);

    // Add this effect to recalculate projections when calories or macros change
    React.useEffect(() => {
        if (!needsCalculation && dailyCalories && macroDistribution) {
            const [currentWeight, weightUnit] = getCurrentWeight();
            
            const projectionResults = calculateProjections({
                currentWeight,
                goalWeight: formData.goalWeight,
                currentBodyFat: formData.currentBodyFat,
                goalBodyFat: formData.goalBodyFat,
                dailyCalories,
                macroDistribution,
                activityLevel: formData.activityLevel,
                cardioActivity: formData.cardioActivity,
                muscleBuildingActivity: formData.muscleBuildingActivity,
                userObject: {
                    sex: formData.sex,
                    age: formData.age,
                    height: formData.height,
                    heightUnit: formData.heightUnit,
                    heightFeet: formData.heightFeet,
                    heightInches: formData.heightInches,
                    weightUnit
                }
            });

            setProjections({
                dataPoints: projectionResults.dataPoints,
                warning: projectionResults.warning,
                recommendations: projectionResults.recommendations
            });
            generateRecommendations();
        }
    }, [dailyCalories, macroDistribution]);

    const handleGoalUpdate = (updates) => {
        setNeedsCalculation(true);
        setProjections(null);
        setRecommendations(null);
        onUpdate(updates);
    };

    const generateRecommendations = () => {
        const weightDiff = formData.goalWeight - getCurrentWeight()[0];
        const isWeightLoss = weightDiff < 0;

        setRecommendations({
            tips: generateTips(isWeightLoss)
        });
    };

    const generateTips = (isWeightLoss) => {
        let tips = [];
        if (isWeightLoss) {
            tips = [
                "Higher protein helps preserve muscle during weight loss",
                "Moderate carbs provide energy for workouts",
                "Adequate fats support hormone function"
            ];
        } else {
            tips = [
                "Increased carbs support muscle growth",
                "Moderate protein enables muscle repair",
                "Balanced fats aid nutrient absorption"
            ];
        }
        return tips;
    };


    return (
        <form className="bt-goal-form">
            <div className="bt-goal-title">
                Set your <b style={{ color: 'rgb(45, 121, 51)' }}>goals</b>
            </div>

            <div className="bt-goal-section">
                <StyledTextField
                    label="Goal Weight"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formData.goalWeight || ''}
                    onChange={(e) => handleGoalUpdate({ goalWeight: parseFloat(e.target.value) })}
                    error={!!errors.goalWeight}
                    helperText={errors.goalWeight}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{getCurrentWeight()[1]}</InputAdornment>,
                    }}
                />
            </div>

            <div className="bt-goal-section">
                <StyledTextField
                    label="Goal Body Fat % (optional)"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={formData.goalBodyFat || ''}
                    onChange={(e) => handleGoalUpdate({ goalBodyFat: parseFloat(e.target.value) })}
                    error={!!errors.goalBodyFat}
                    helperText={errors.goalBodyFat}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </div>

            {!needsCalculation && dailyCalories && macroDistribution && (
                <>
                 {projections && (
                        <div className="bt-goal-projection">
                        <Typography variant="subtitle1" gutterBottom>
                        Weight & Body Fat Projection
                            </Typography>
                            <Typography gutterBottom style={{ color: '#888888', marginBottom: '20px', fontSize:'12.5px' }}>
                                Change calories/macros to see your projection change. Note this is an approximation and our AI consultant will help you modify your targets based on your tracking data.
                            </Typography>
                            <WeightProjectionGraph
                                data={projections.dataPoints}
                                weightUnit={getCurrentWeight()[1]}
                            />
                        </div>
                    )}
                    <div className="bt-goal-section">
                        <Typography variant="subtitle1" gutterBottom>
                            Daily Caloric Intake
                        </Typography>
                        <div className="bt-goal-calories">
                            <GreenSlider
                                value={dailyCalories}
                                onChange={(_, value) => {
                                    onUpdate({ dailyCalories: value });
                                }}
                                min={formData.sex === 'male' ? 1400 : 1000}
                                max={4000}
                                step={25}
                                valueLabelDisplay="on"
                            />
                        </div>
                    </div>

                    <div className="bt-goal-section">
                        <Typography variant="subtitle1" gutterBottom>
                            Recommended Macro Split
                        </Typography>
                        {recommendations && (
                            <div className="bt-goal-macro-tips">
                                {recommendations.tips.map((tip, index) => (
                                    <div key={index} className="bt-goal-macro-tip">
                                        {tip}
                                    </div>
                                ))}
                            </div>
                        )}
                        <MacroSlider
                            macroDistribution={macroDistribution}
                            setMacroDistribution={(newMacros) => {
                                onUpdate({ macroDistribution: newMacros });
                            }}
                            dailyCalories={dailyCalories}
                        />
                    </div>

                    {projections && projections.warning && projections.warning.length > 0 && (
                        <div className="bt-goal-warning">
                            <div className="bt-goal-warning-title">
                                Important Considerations
                            </div>
                            <div className="bt-goal-warning-content">
                                {Array.isArray(projections.warning) ?
                                    projections.warning.map((warning, index) => (
                                        <div key={index} className="bt-goal-warning-item">
                                            {warning}
                                        </div>
                                    ))
                                    :
                                    <div className="bt-goal-warning-item">
                                        {projections.warning}
                                    </div>
                                }
                            </div>
                        </div>
                    )}

                    {projections && projections.recommendations && (
                        <div className="bt-goal-recommendations">
                            <div className="bt-goal-recommendations-title">
                                Recommendations to Optimize Your Results
                            </div>
                            <div className="bt-goal-recommendations-content">
                                {projections.recommendations.split('\n\n').map((recommendation, index) => (
                                    <div key={index} className="bt-goal-recommendation-item">
                                        {recommendation}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                   
                </>
            )}
        </form>
    );
};

export default GoalSettingStep;