import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from 'contexts/user_context';
import './step_tracker_card.css';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Slider,
} from '@material-ui/core';

const StepTrackerCard = ({ calendarDate }) => {
  const { getStepData, setStepData, getStepGoal, setStepGoal, userObject } = useContext(UserContext);
  
  const [steps, setSteps] = useState(getStepData(calendarDate));
  const [stepGoalState, setStepGoalState] = useState(getStepGoal());
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [inputDialogOpen, setInputDialogOpen] = useState(false);
  const [newSteps, setNewSteps] = useState('');

  useEffect(() => {
    const initialSteps = getStepData(calendarDate);
    setSteps(initialSteps);
  }, [calendarDate, getStepData]);

  useEffect(() => {
    setStepGoalState(getStepGoal());
  }, [userObject.stepGoal]);

  const handleSaveSteps = () => {
    const stepsValue = parseInt(newSteps, 10);
    setSteps(stepsValue);
    setStepData(calendarDate, stepsValue);
    setInputDialogOpen(false);
    setNewSteps('');
  };

  const handleSaveSettings = () => {
    setStepGoal(stepGoalState);
    setSettingsOpen(false);
  };
  const progressBar = (steps / stepGoalState) * 100;
  const progress = Math.min(97, Math.max(3, (steps / stepGoalState) * 100));

  const getProgressColor = (steps, goal) => {
    const percentage = (steps / goal) * 100;
    
    if (percentage >= 100) {
      return {
        bar: 'rgba(76, 175, 80, 0.2)', // green
        icon: '#4CAF50'
      };
    } else if (percentage >= 75) {
      return {
        bar: 'rgba(241, 196, 15, 0.2)', // yellow
        icon: '#f1c40f'
      };
    } else if (percentage >= 50) {
      return {
        bar: 'rgba(243, 156, 18, 0.2)', // orange
        icon: '#f39c12'
      };
    } else {
      return {
        bar: 'rgba(241, 160, 146, 0.2)', // original red
        icon: '#f1a092'
      };
    }
  };

  const colors = getProgressColor(steps, stepGoalState);

  // Format the date for the dialog title
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="step-tracker-card">
      <div className="step-tracker-header">
        <div className="header-left">Steps</div>
        <div className="header-center">
          {steps.toLocaleString()} / {stepGoalState.toLocaleString()}
        </div>
        <div className="header-right">
          <MoreHorizIcon
            className="settings-icon"
            onClick={() => setSettingsOpen(true)}
            style={{ cursor: 'pointer', color: 'grey' }}
          />
        </div>
      </div>

      <div 
        className="step-progress-container"
        onClick={() => setInputDialogOpen(true)}
        style={{ cursor: 'pointer' }}
      >
        <div 
          className="step-progress-bar"
          style={{ 
            width: `${Math.min(100, progressBar)}%`,
            backgroundColor: colors.bar
          }}
        />
        <DirectionsWalkIcon 
          className="step-icon"
          style={{ 
            color: colors.icon,
            left: `calc(${Math.min(100, progress)}% - 12px)`,
          }}
        />
      </div>

      {/* Input Dialog */}
      <Dialog open={inputDialogOpen} onClose={() => setInputDialogOpen(false)}>
        <DialogTitle>Log Steps for {formatDate(calendarDate)}</DialogTitle>
        <DialogContent style={{ paddingTop: '10px' }}>
          <TextField
            autoFocus
            label="Steps"
            type="number"
            fullWidth
            value={newSteps}
            onChange={(e) => setNewSteps(e.target.value)}
            inputProps={{ min: 0 }}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInputDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleSaveSteps}
            color="primary"
            disabled={!newSteps || parseInt(newSteps, 10) < 0}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Settings Dialog */}
      <Dialog open={settingsOpen} onClose={handleSaveSettings}>
        <DialogTitle>Step Goal Settings</DialogTitle>
        <DialogContent>
          <div className="settings-content" style={{ padding: '0px 20px' }}>
            <div className="settings-item">
              <p>Daily Goal: {stepGoalState.toLocaleString()} steps</p>
              <Slider
                style={{ color: 'rgb(89 169 239)' }}
                value={stepGoalState}
                onChange={(e, newValue) => setStepGoalState(newValue)}
                min={2000}
                max={20000}
                step={500}
                marks
                valueLabelDisplay="auto"
              />
            </div>
            <div style={{ marginTop: '20px', fontSize: '14px', color: '#666' }}>
              <p>Recommended daily step goals:</p>
              <ul style={{ marginTop: '5px' }}>
                <li>Starting point for health: 7,000 steps</li>
                <li>Active lifestyle: 10,000 steps</li>
                <li>High activity: 12,000+ steps</li>
              </ul>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveSettings} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StepTrackerCard; 