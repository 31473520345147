import React, { useState, createContext, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid';
// import axios from 'axios';
import { UserContext } from './user_context';
import { AuthContext } from './auth_context';
import { getDateMonthYear } from 'helpers/date_helpers';
import { RecipeBooksContext } from './recipe_books_context';
import { getFirestore, collection, doc, getDoc, getDocs, setDoc, deleteDoc, writeBatch, query, where } from "firebase/firestore";
import axios from 'axios';
import _ from 'lodash';
import { Browser } from '@capacitor/browser';
import { compareByTimestamp, decimalToFraction } from 'helpers/recipe_helpers';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';


// import { AuthContext } from './auth_context';

// import { getDateMonthYear } from 'helpers/date_helpers';


// import { collection, query, where, getDocs } from "firebase/firestore";




export const CartContext = createContext();

//cartObject is like [{name:"Salmon",pinned:true,calendarPositionX:0,calendarCategory:snack}]

export function getMealDateString(meal) {
    if (meal.mealDate) {
        return meal.mealDate; // Already in YYYY-MM-DD format
    }
    if (meal.calendarDate) {
        // Convert legacy calendarDate to YYYY-MM-DD
        const date = new Date(meal.calendarDate);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const localDate = new Date(date.getTime() - userTimezoneOffset);
        return localDate.toISOString().split('T')[0];
    }
    return null;
}

export const CartContextProvider = (props) => {
    const [calendarMealList, setCalendarMealList] = useState([]);
    const [favoriteFoodList, setFavoriteFoodList] = useState([]);
    const [cookingRecipeList, setCookingRecipeList] = useState([]);
    const [checkedIngredients, setCheckedIngredients] = React.useState({});


    // const menu = React.useContext(MenuContext).getActiveMenu();
    const getCalories = useContext(UserContext).getCalories
    const userObject = useContext(UserContext).userObject
    const setUserObject = useContext(UserContext).setUserObject
    // const getActiveMenu = useContext(MenuContext).getActiveMenu
    const { currentUser } = useContext(AuthContext)
    const loadRecipeBooksAndRecipes = useContext(RecipeBooksContext).loadRecipeBooksAndRecipes
    const resetRecipeBookObjects = useContext(RecipeBooksContext).resetRecipeBookObjects
    const resetUserObject = React.useContext(UserContext).resetUserObject
    const recipeList = React.useContext(RecipeBooksContext).recipeList

    var db = getFirestore();
    var calendarMealCollectionRef;
    var favoriteFoodCollectionRef;
    var cookingRecipeCollectionRef;

    if (currentUser) {
        calendarMealCollectionRef = collection(db, "users", currentUser.uid, "calendarMeals")
        favoriteFoodCollectionRef = collection(db, "users", currentUser.uid, "favoriteFoods")
        cookingRecipeCollectionRef = collection(db, "users", currentUser.uid, "coookingRecipes")

    }

    // var userCollectionRef = collection(db, "users")


    // const menuObject = useContext(MenuContext).menuObject
    // const setMenuObject = useContext(MenuContext).setMenuObject
    // const getCurrentDeliveryDate = React.useContext(MenuContext).getCurrentDeliveryDate
    // const getActiveMenuWithRefresh = useContext(MenuContext).getActiveMenuWithRefresh
    // const foodVendorID = React.useContext(UserContext).userObject.foodVendorID
    // const getActiveMenuId = useContext(MenuContext).getActiveMenuId

    // function saveCalendarMealList(newCalendarMealList) {
    //     if (userObject.generateDays != null & userObject.loaded) {

    //         let docRef = doc(db, "users", currentUser.uid)

    //         calendarMealCollectionRef
    //         // usersRef.doc(currentUser.uid).update(userObject).catch((err) => {
    //         //      console.error(err)
    //         //  })
    //     }
    //     setCalendarMealList(newCalendarMealList)

    // }


    function updateMealDates(mealDate) {
        // Convert to local date string using timezone offset
       

        let updatedMealDates = userObject.mealDates ? [...userObject.mealDates] : [];

        if (!updatedMealDates.includes(mealDate)) {
            updatedMealDates.push(mealDate);
            updatedMealDates.sort();
            setUserObject({
                ...userObject,
                mealDates: updatedMealDates,
            });
        }
    }

    function saveCalendarMeal(calendarMeal) {
        if (userObject.loaded) {
            let mealToSave = { ...calendarMeal };
            
            // Add mealDate field while preserving calendarDate for backwards compatibility
            if (!mealToSave.mealDate) {
                mealToSave.mealDate = getMealDateString(mealToSave);
            }
            
            let tempDoc = doc(calendarMealCollectionRef, mealToSave.id);
            setDoc(tempDoc, mealToSave);
            
            updateMealDates(mealToSave.mealDate);
        }
    }


    const loadCalendarMeals = async () => {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        const querySnapshot = await getDocs(query(calendarMealCollectionRef, where('calendarDate', '>', thirtyDaysAgo)));
        let newCalendarMealList = []

        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let tempDoc = doc.data()
            if (tempDoc.calendarDate) {
                tempDoc['calendarDate'] = new Date(tempDoc.calendarDate.seconds * 1000)
            }
            newCalendarMealList.push(tempDoc);
        })
        setCalendarMealList(newCalendarMealList)
    }

    const loadFavoriteFoods = async () => {
        const querySnapshot = await getDocs(favoriteFoodCollectionRef);
        let newFavoriteFoodList = []

        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let tempDoc = doc.data()

            newFavoriteFoodList.push(tempDoc);
        })
        newFavoriteFoodList.sort(compareByTimestamp)
        setFavoriteFoodList(newFavoriteFoodList)
    }

    const loadCookingRecipeList = async () => {
        const querySnapshot = await getDocs(cookingRecipeCollectionRef);
        let newCookingRecipeList = []

        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let tempDoc = doc.data()

            newCookingRecipeList.push(tempDoc);
        })
        setCookingRecipeList(newCookingRecipeList)
    }

    const loadUsers = async () => {
        if (!userObject.loaded && currentUser) {

            try {
                //this loads once but doesn't update via a listener

                let docRef = doc(db, "users", currentUser.uid)

                getDoc(docRef).then((doc) => {
                    const tempUserObject = doc.data();
                    if (!tempUserObject) { return }
                    if (!tempUserObject.macroBreakdown) {
                        tempUserObject.macroBreakdown = userObject.macroBreakdown
                    }
                    setUserObject({ ...tempUserObject, loaded: true, calendarFirstDate: userObject.calendarFirstDate, windowWidth: userObject.windowWidth, calendarPosition: userObject.calendarPosition, lastUsedPlatform:Capacitor.getPlatform() })
                    loadCalendarMeals()
                    loadRecipeBooksAndRecipes(tempUserObject.recipeBooks)
                    loadFavoriteFoods()
                    loadCookingRecipeList()

                }).catch((e) => {
                    console.log(e)
                })

                //
            }

            catch (e) {
                console.log(e)
            }
        }
    }

    //previous function used to load vendor metadata and user/cart from local storage
    // async function loadData(specificVendorId = undefined) {
    //     if (!userObject.loaded) {
    //         const userData = localStorage.getItem("user-3");
    //         let json;
    //         if (userData) {
    //             json = JSON.parse(userData)
    //         }
    //         else {
    //             if (specificVendorId) {
    //                 const resp = await axios.get('vendorOffer/' + specificVendorId);
    //                 await getActiveMenuWithRefresh(specificVendorId);
    //                 setUserObject({ ...userObject, foodVendor: resp.data.Name, foodVendorDeliveryRate:resp.data.DeliveryRate,foodVendorID:specificVendorId, loaded: true });
    //             }
    //             else{
    //                 setUserObject({ ...userObject, loaded: true })
    //                 setMenuObject({ ...menuObject, loaded: true })
    //             }
    //             return
    //         }
    //         if (specificVendorId && json.foodVendorID !== specificVendorId) {
    //             //If we were passed a specific vendor Id then we should load the store for that vendor.
    //             //Note if we're passed the same vendor we have on local storage we can load from local storage.

    //             const resp = await axios.get('vendorOffer/' + specificVendorId);
    //             json.foodVendor=resp.data.Name
    //             json.foodVendorDeliveryRate=resp.data.DeliveryRate
    //             json.foodVendorID=specificVendorId
    //             await getActiveMenuWithRefresh(specificVendorId);
    //             setUserObject({ ...json, loaded: true });
    //         }

    //         else {
    //             const menuObject = await getActiveMenuWithRefresh(json.foodVendorID);
    //             setUserObject({ ...json, loaded: true });
    //             let cartData = localStorage.getItem("cart-1");
    //             if (cartData) {
    //                 let cartJson = JSON.parse(cartData)
    //                 let cart = []
    //                 for (let cartIndex in cartJson) {
    //                     if (cartJson[cartIndex].type === "custom") {
    //                         cart.push(cartJson[cartIndex])
    //                         continue
    //                     }
    //                     for (let menuIndex in menuObject) {
    //                         if (menuObject[menuIndex].id === cartJson[cartIndex].id) {
    //                             cart.push({ ...menuObject[menuIndex], optionsChosen: cartJson[cartIndex].optionsChosen, calendarDate: cartJson[cartIndex].calendarDate, calendarCategory: cartJson[cartIndex].calendarCategory, pinned: cartJson[cartIndex].pinned, key: cartJson[cartIndex].key })
    //                             break
    //                         }
    //                     }
    //                 }
    //                 setCartObject(cart);
    //             }
    //         }
    //     }
    //     else {
    //         setMenuObject({ ...menuObject, loaded: true })
    //     }
    // }
    function removeMealDate(mealDate, calendarMealList) {
       

        // Check if other meals exist for this date in the updated calendarMealList
        const hasOtherMeals = calendarMealList.some((m) => {
            const mealDateTemp = new Date(m.calendarDate);
            const mealLocalDate = new Date(mealDateTemp.getTime() - mealDateTemp.getTimezoneOffset() * 60000);
            return mealLocalDate.toISOString().split('T')[0] === mealDate;
        });

        let updatedMealDates = userObject.mealDates ? [...userObject.mealDates] : [];

        if (!hasOtherMeals) {
            // No other meals for this date, remove it from mealDates
            updatedMealDates = updatedMealDates.filter((date) => date !== mealDate);
        }

        // Update the userObject with the new mealDates
        setUserObject({
            ...userObject,
            mealDates: updatedMealDates,
        });
    }
    
    

    function deleteMeal(meal) {
        if (userObject.loaded) {
            let tempDoc = doc(calendarMealCollectionRef, meal.id);
            deleteDoc(tempDoc);
    
            // Create an updated calendarMealList without the deleted meal
            const updatedCalendarMealList = calendarMealList.filter(
                (calendarMeal) => calendarMeal.id !== meal.id
            );
    
            // Update mealDates and recalculate streak using the updated list
            removeMealDate(meal.mealDate, updatedCalendarMealList);
    
            // Update the state with the new calendarMealList
            setCalendarMealList(updatedCalendarMealList);
        }
    }
    
    

    function addRecipeToCookingList(recipe) {
        if (userObject.loaded) {
            let newRecipe = { ...recipe, cooking_recipe_id: uuidv4() }
            let tempDoc = doc(cookingRecipeCollectionRef, newRecipe.cooking_recipe_id)
            let newCookingRecipeList = cookingRecipeList.concat([])
            newCookingRecipeList.unshift(newRecipe)
            setCookingRecipeList(newCookingRecipeList)
            setDoc(tempDoc, newRecipe)
        }
    }

    function addRecipesToCookingList(recipes) {
        if (userObject.loaded) {
            let newCookingRecipeList = cookingRecipeList.concat([])
            for (let recipe of recipes) {
                let tempDoc = doc(cookingRecipeCollectionRef, recipe.cooking_recipe_id)
                setDoc(tempDoc, recipe)
                newCookingRecipeList.push(recipe)
            }
            setCookingRecipeList(newCookingRecipeList)
        }
    }


    function removeRecipeFromCookingList(cooking_recipe_id) {
        if (userObject.loaded) {

            let tempDoc = doc(cookingRecipeCollectionRef, cooking_recipe_id)

            deleteDoc(tempDoc)
            setCookingRecipeList(cookingRecipeList.filter((recipe, i) => recipe.cooking_recipe_id !== cooking_recipe_id));

            // usersRef.doc(currentUser.uid).update(userObject).catch((err) => {
            //     console.error(err)
            // })
        }
    }

    function updateCookingRecipe(recipe) {
        let newCookingRecipeList = cookingRecipeList.concat([])

        for (let cookingRecipeIndex in newCookingRecipeList) {
            if (newCookingRecipeList[cookingRecipeIndex].cooking_recipe_id === recipe.cooking_recipe_id) {
                newCookingRecipeList[cookingRecipeIndex] = recipe
                setCookingRecipeList(newCookingRecipeList);
                let tempDoc = doc(cookingRecipeCollectionRef, recipe.cooking_recipe_id)
                setDoc(tempDoc, recipe)
                return;

            }
        }
    }

    function addFavoriteFood(favoriteFood) {
        if (userObject.loaded) {
            let tempDoc = doc(favoriteFoodCollectionRef, favoriteFood.food_id)
            let newFavoriteFood = { ...favoriteFood, timestamp: Date.now() }
            let newFavoriteFoodList = favoriteFoodList.filter((favFood, i) => favFood.food_id !== newFavoriteFood.food_id)
            newFavoriteFoodList.unshift(newFavoriteFood)
            setFavoriteFoodList(newFavoriteFoodList)
            setDoc(tempDoc, newFavoriteFood)
            // usersRef.doc(currentUser.uid).update(userObject).catch((err) => {
            //     console.error(err)
            // })
        }
        // setUserObject(userObject)
    }

    function addFavoriteCustomLog(favoriteFood, createId = true) {
        if (userObject.loaded) {
            let newFavoriteFood = { ...favoriteFood, food_id: createId ? uuidv4() : favoriteFood.food_id, type: 'meal', food_name: favoriteFood.name, portionChosen: 1, timestamp: Date.now() }
            let tempDoc = doc(favoriteFoodCollectionRef, newFavoriteFood.food_id)

            let newFavoriteFoodList = favoriteFoodList.concat([])
            newFavoriteFoodList.unshift(newFavoriteFood)
            setFavoriteFoodList(newFavoriteFoodList)
            setDoc(tempDoc, newFavoriteFood)
            // usersRef.doc(currentUser.uid).update(userObject).catch((err) => {
            //     console.error(err)
            // })
        }
        // setUserObject(userObject)
    }

    function removeFoodFromFavorites(food_id) {
        if (userObject.loaded) {

            let tempDoc = doc(favoriteFoodCollectionRef, food_id)

            deleteDoc(tempDoc)
            setFavoriteFoodList(favoriteFoodList.filter((favoriteFood, i) => favoriteFood.food_id !== food_id));

            // usersRef.doc(currentUser.uid).update(userObject).catch((err) => {
            //     console.error(err)
            // })
        }
    }

    async function deleteMeals(meals) {
        //meals is an array of meals
        if (userObject.loaded) {
            const batchDBUpdate = writeBatch(db);
            for (let meal of meals) {
                const calendarMealDocRef = doc(calendarMealCollectionRef, meal.id);
                batchDBUpdate.delete(calendarMealDocRef)

            }
            const mealIds = meals.map(meal => meal.id);
            let newCalendarMealList = calendarMealList.filter((meal) => !mealIds.includes(meal.id))
            setCalendarMealList(newCalendarMealList)
            await batchDBUpdate.commit()
        }

    }

    function canMoveMeal(calendarDate, calendarCategory, meal) {
        const targetDate = getMealDateString({ calendarDate });
        const mealDate = getMealDateString(meal);
        
        if (targetDate === mealDate && calendarCategory === meal.calendarCategory) {
            return false;
        }
        return true;
    }

    function togglePinMeal(calendarMealId) {
        let newCalendarMealList = calendarMealList.concat([])

        for (let calendarMealIndex in newCalendarMealList) {
            if (newCalendarMealList[calendarMealIndex].id === calendarMealId) {
                newCalendarMealList[calendarMealIndex].pinned = !newCalendarMealList[calendarMealIndex].pinned
                setCalendarMealList(newCalendarMealList);
                saveCalendarMeal(newCalendarMealList[calendarMealIndex])
                return;

            }
        }
    }


    function addOrMoveMealToColumn(calendarDate, meal, calendarCategory = undefined, pinned = true) {
        let newCalendarMealList = calendarMealList.concat([]);
        
        // Convert calendarDate to mealDate format
        const date = new Date(calendarDate);
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const localDate = new Date(date.getTime() - userTimezoneOffset);
        const mealDateStr = localDate.toISOString().split('T')[0];

        // Find whether the meal exists already
        let filteredMealList = newCalendarMealList.filter((calendarMeal) => calendarMeal.id === meal.id);
        let calendarMeal;

        if (filteredMealList[0]) {
            // If it exists, we move the meal
            calendarMeal = filteredMealList[0];
            calendarMeal.calendarDate = calendarDate; // keep for backwards compatibility
            calendarMeal.mealDate = mealDateStr; // add new date format
            calendarMeal.calendarCategory = calendarCategory ? calendarCategory : calendarMeal.calendarCategory;

            setCalendarMealList(newCalendarMealList.filter((calendarMeal) => calendarMeal.id !== meal.id).concat(calendarMeal));
        } else {
            // Adding a new meal
            if (!calendarCategory) {
                calendarCategory = "lunch";
            }
            calendarMeal = { 
                ...meal, 
                portion: meal.portion ? meal.portion : 1, 
                pinned: pinned, 
                calendarDate: calendarDate, // keep for backwards compatibility
                mealDate: mealDateStr, // add new date format
                calendarCategory: calendarCategory, 
                id: uuidv4() 
            };
            delete calendarMeal['cooking_recipe_id'];
            setCalendarMealList(calendarMealList.concat([calendarMeal]));
        }

        LocalNotifications.cancel({ notifications: [{ id: 1 }] });
        saveCalendarMeal(calendarMeal);
    }

    function getMealsForCheckout() {
        //this function groups the meals and reports the quantity of each, returns an object like {id1->{name, category, price, quantity}, id2->{name, category, price, quantity}}
        let mealsForCheckout = {}
        for (let mealIndex in calendarMealList) {
            let meal = calendarMealList[mealIndex]
            if (meal.type === "ready-made") {
                if (Object.keys(mealsForCheckout).includes(getMealAttributeWithOptions(meal, "name"))) {
                    mealsForCheckout[getMealAttributeWithOptions(meal, "name")] = { ...mealsForCheckout[getMealAttributeWithOptions(meal, "name")], quantity: mealsForCheckout[getMealAttributeWithOptions(meal, "name")].quantity + 1 }
                }
                else {
                    mealsForCheckout[getMealAttributeWithOptions(meal, "name")] = { name: getMealAttributeWithOptions(meal, "name"), category: meal.category, price: getMealAttributeWithOptions(meal, "price"), picture: meal.picture, quantity: 1 }
                }

            }

        }
        return mealsForCheckout
    }

    function duplicateMeal(meal) {
        let newMeal = { ...meal, id: uuidv4() }
        setCalendarMealList(calendarMealList.concat([newMeal]))
        saveCalendarMeal(newMeal)
    }

    // function deleteAllMealsByIdList(idList) {
    //     let newCalendarMealList = calendarMealList.concat([])
    //     for (let idIndex in idList) {
    //         // eslint-disable-next-line
    //         newCalendarMealList = newCalendarMealList.filter((calendarMeal, i) => calendarMeal.id != idList[idIndex])
    //     }
    //     setCalendarMealList(newCalendarMealList)
    // }

    function deleteAllMealsByName(name) {
        // eslint-disable-next-line
        saveCartObject(userObject.cart.filter((cartMeal, i) => getMealAttributeWithOptions(cartMeal, "name") != name))
    }

    function deleteSingleMealByName(name) {
        let meal = {}
        for (let cartIndex in userObject.cart) {
            // eslint-disable-next-line
            if (getMealAttributeWithOptions(userObject.cart[cartIndex], "name") == name)
                meal = userObject.cart[cartIndex]
        }
        deleteMeal(meal)
    }


    function setCartMeal(meal) {
        let newCalendarMealList = calendarMealList.map((calendarMeal) => {
            if (calendarMeal.id === meal.id) {
                return meal
            }
            else {
                return calendarMeal
            }
        })
        setCalendarMealList(newCalendarMealList)
        saveCalendarMeal(meal)
    }


    // function getPinnedMealsForDay(calendarPositionX) {
    //     return userObject.cart.filter(function (e) {
    //         return (e.pinned && e.calendarPositionX === calendarPositionX)
    //     })
    // }

    async function generateDailyMeals(calendarDate, slot = "all") {
        let response;
        if (userObject.proteins && userObject.carbs && userObject.fats && userObject.macroBreakdown) {
            console.log(userObject)
            response = await axios.get('generateWithMacros/', {
                params: {
                    calories: slot === "all" ? getCalories() : getCalories() / 4,
                    protein: slot === "all" ? userObject.proteins : userObject.macroBreakdown.protein[slot] * userObject.proteins / 100,
                    carbs: slot === "all" ? userObject.carbs : userObject.macroBreakdown.carbs[slot] * userObject.carbs / 100,
                    fat: slot === "all" ? userObject.fats : userObject.macroBreakdown.fat[slot] * userObject.fats / 100,
                    slot: slot
                }
            })
        }
        else {
            response = await axios.get('generate/', {
                params: {
                    calories: slot === "all" ? getCalories() : getCalories() / 4,
                    slot: slot
                }
            })

        }
        await addMealsFromGenerate(calendarDate, response.data, slot)

    }




    function addAndPortionMeal(meal) {
        for (let ingredient of meal.ingredients) {
            ingredient.amountString = decimalToFraction(ingredient.amount * meal.portion)
            ingredient.amount = ingredient.amount * meal.portion
        }

        for (let mealNutrient of meal.nutrition.nutrients) {
            mealNutrient['amount'] = 0
            for (let ingredient of meal.ingredients) {
                for (let ingredientNutrient of ingredient.nutrition.nutrients) {
                    if (mealNutrient.name === ingredientNutrient.name) {
                        if (ingredientNutrient['amount'] && ingredient['amount']) {
                            mealNutrient['amount'] = mealNutrient['amount'] + (ingredientNutrient['amount'] * ingredient['amount'])
                        }
                    }
                }
            }
        }
        saveCalendarMeal(meal)
        return meal
    }

    async function addMealsFromGenerate(calendarDate, generateObject, slot) {
        let newCalendarMealList = []

        //first remove all non-pinned meals for that day
        let mealsToDelete = []
        for (let meal of calendarMealList) {
            if (getDateMonthYear(new Date(meal.calendarDate)) === getDateMonthYear(new Date(calendarDate))
                && (slot === "all" || meal.calendarCategory === slot)) {
                mealsToDelete.push(meal)
            }
            else {
                newCalendarMealList.push(meal)
            }
        }
        await deleteMeals(mealsToDelete)

        newCalendarMealList = newCalendarMealList.filter(function (e) { return (e.pinned || e.calendarDate !== calendarDate) })

        for (let recipe of recipeList) {
            if (recipe.id === generateObject.pickedBreakfastMain) {
                let calendarMeal = _.cloneDeep({ ...recipe, pinned: false, portion: generateObject.chosenPortion ? generateObject.chosenPortion : generateObject.portions.breakFastMainPortion, calendarDate: calendarDate, calendarCategory: 'breakfast', id: uuidv4() })
                calendarMeal = addAndPortionMeal(calendarMeal)
                newCalendarMealList.push(calendarMeal)

            }
            if (recipe.id === generateObject.pickedLunchMain) {
                let calendarMeal = _.cloneDeep({ ...recipe, pinned: false, portion: generateObject.chosenPortion ? generateObject.chosenPortion : generateObject.portions.lunchMainPortion, calendarDate: calendarDate, calendarCategory: 'lunch', id: uuidv4() })
                calendarMeal = addAndPortionMeal(calendarMeal)
                newCalendarMealList.push(calendarMeal)
            }
            if (recipe.id === generateObject.pickedLunchSide) {
                let calendarMeal = _.cloneDeep({ ...recipe, pinned: false, portion: generateObject.chosenPortion ? generateObject.chosenPortion : generateObject.portions.lunchSidePortion, calendarDate: calendarDate, calendarCategory: 'lunch', id: uuidv4() })
                calendarMeal = addAndPortionMeal(calendarMeal)
                newCalendarMealList.push(calendarMeal)
            }
            if (recipe.id === generateObject.pickedDinnerMain) {
                let calendarMeal = _.cloneDeep({ ...recipe, pinned: false, portion: generateObject.chosenPortion ? generateObject.chosenPortion : generateObject.portions.dinnerMainPortion, calendarDate: calendarDate, calendarCategory: 'dinner', id: uuidv4() })
                calendarMeal = addAndPortionMeal(calendarMeal)
                newCalendarMealList.push(calendarMeal)
            }
            if (recipe.id === generateObject.pickedDinnerSide) {
                let calendarMeal = _.cloneDeep({ ...recipe, pinned: false, portion: generateObject.chosenPortion ? generateObject.chosenPortion : generateObject.portions.dinnerSidePortion, calendarDate: calendarDate, calendarCategory: 'dinner', id: uuidv4() })
                calendarMeal = addAndPortionMeal(calendarMeal)
                newCalendarMealList.push(calendarMeal)
            }
            if (recipe.id === generateObject.pickedSnack) {
                let calendarMeal = _.cloneDeep({ ...recipe, pinned: false, portion: generateObject.chosenPortion ? generateObject.chosenPortion : generateObject.portions.snackPortion, calendarDate: calendarDate, calendarCategory: 'snack', id: uuidv4() })
                calendarMeal = addAndPortionMeal(calendarMeal)
                newCalendarMealList.push(calendarMeal)
            }
        }
        setCalendarMealList(newCalendarMealList)
    }


    async function addMealsFromCopy(meals, calendarDate, calendarCategory) {
        let newCalendarMealList = calendarMealList.concat([]);

        for (let meal of meals) {
            let calendarMeal = _.cloneDeep({
                ...meal,
                calendarDate: calendarDate, // keep for backwards compatibility
                mealDate: getMealDateString({ calendarDate }), // ensure mealDate is set
                calendarCategory: calendarCategory,
                id: uuidv4()
            });
            
            saveCalendarMeal(calendarMeal);
            newCalendarMealList.push(calendarMeal);
        }

        setCalendarMealList(newCalendarMealList);
        LocalNotifications.cancel({ notifications: [{ id: 1 }] });
    }

    //         let breakfast = day.breakfast
    //          let lunch = day.lunch
    //         let dinner = day.dinner
    //         let snack = day.snack
    //          if (breakfast) {
    //            for (let mealId in breakfast) {
    //                 for (let mealIndex in menu) {
    //                      if (menu[mealIndex].id === breakfast[mealId]) {
    //                          newCartObject.push({ ...menu[mealIndex], calendarPositionX: calendarPositionX, calendarCategory: 'breakfast', pinned: false, key: uuidv4() })
    //                         break
    //                     }
    //                }
    //              }
    //          }
    //         if (lunch) {
    //              for (let mealId in lunch) {

    //                 for (let mealIndex in menu) {

    //                      if (menu[mealIndex].id === lunch[mealId]) {
    //                          newCartObject.push({ ...menu[mealIndex], calendarPositionX: calendarPositionX, calendarCategory: 'lunch', pinned: false, key: uuidv4() })
    //                          break
    //                      }
    //                  }
    //              }
    //         }
    //          if (dinner) {
    //             for (let mealId in dinner) {
    //                 for (let mealIndex in menu) {
    //                     if (menu[mealIndex].id === dinner[mealId]) {
    //                         newCartObject.push({ ...menu[mealIndex], calendarPositionX: calendarPositionX, calendarCategory: 'dinner', pinned: false, key: uuidv4() })
    //                         break
    //                     }
    //                 }
    //              }
    //         }
    //         if (snack) {
    //             for (let mealId in snack) {
    //                 for (let mealIndex in menu) {
    //                      if (menu[mealIndex].id === snack[mealId]) {
    //                          newCartObject.push({ ...menu[mealIndex], calendarPositionX: calendarPositionX, calendarCategory: 'snack', pinned: false, key: uuidv4() })
    //                         break
    //                      }
    //                 }
    //             }
    //          }
    //      }

    //      setCartObjectWithLocalStorage(newCartObject)
    //  }

    // function findUnavailableMealsInCart(chosenDeliveryDate) {
    //     //This function finds the meals in the cart that would no longer be available if the menu were to change to another delivery day
    //     let mealsNotAvailable = []

    //     const newMenu = getActiveMenu(chosenDeliveryDate)
    //     let newMenuIds = []
    //     for (let newMenuIndex in newMenu) {
    //         newMenuIds.push(newMenu[newMenuIndex].id)
    //     }


    //     for (let mealIndex in userObject.cart) {
    //         if (!newMenuIds.includes(userObject.cart[mealIndex].id)) {
    //             mealsNotAvailable.push(userObject.cart[mealIndex])
    //         }
    //     }
    //     return (mealsNotAvailable)
    // }

    function triggerCalendarMealStateRefresh() {
        let newCalendarMealList = calendarMealList.concat([])
        setCalendarMealList(newCalendarMealList)
    }

    function resetAllContexts() {
        //only used during logout
        setCalendarMealList([])
        resetRecipeBookObjects()
        resetUserObject()
    }

    function getMealAttributeWithOptions(meal, attribute) {
        if (!meal.optionsChosen) {
            return meal[attribute]
        }
        let optionsChosenKeys = Object.keys(meal.optionsChosen)
        let attributeCopy = meal[attribute].valueOf();

        for (let optionsChosenKeysIndex in optionsChosenKeys) {
            let optionChosen = meal.optionsChosen[optionsChosenKeys[optionsChosenKeysIndex]]
            if (optionChosen) {
                if (attribute === "name") {
                    attributeCopy = attributeCopy + " (" + optionChosen.optionName + ")"
                }
                else {
                    attributeCopy = attributeCopy + optionChosen[attribute + "ToAdd"]
                }
            }

        }
        return attributeCopy
    }

    function prepareIngredientsToOrder(chosenWeek) {
        //this function returns ingredients with their amount in grams
        let recipesToOrder = []

        for (let recipe of cookingRecipeList) {
            if (recipe.week === chosenWeek) {
                recipesToOrder.push(recipe)
            }
        }



        //ingredientsToOrder is of the shape of {id->{name:'rice', amount:0,amountInGrams:0}}
        let ingredientsToOrder = {}
        for (let recipe of recipesToOrder) {

            for (let recipeIngredient of recipe.ingredients) {
                console.log(recipeIngredient)
                if (Object.keys(ingredientsToOrder).includes(recipeIngredient.name.toString())) {
                    if (recipeIngredient.selectedUnit === "") {
                        ingredientsToOrder[recipeIngredient.name] = { ...ingredientsToOrder[recipeIngredient.name], amount: ingredientsToOrder[recipeIngredient.name].amount + recipeIngredient.amount }
                    }

                    ingredientsToOrder[recipeIngredient.name] = { ...ingredientsToOrder[recipeIngredient.name], amountInGrams: Math.ceil(10 * (ingredientsToOrder[recipeIngredient.name].amountInGrams + recipeIngredient.amount * recipeIngredient.nutrition.weightPerServing.amount)) / 10 }


                }
                else {
                    if (recipeIngredient.selectedUnit === '') {
                        ingredientsToOrder[recipeIngredient.name] = { name: recipeIngredient.name, amount: recipeIngredient.amount }
                    }
                    else {
                        ingredientsToOrder[recipeIngredient.name] = { name: recipeIngredient.name, amountInGrams: Math.ceil(10 * (recipeIngredient.amount * recipeIngredient.nutrition.weightPerServing.amount)) / 10 }
                    }
                }

            }
        }

        return ingredientsToOrder
    }


    function prepareIngredientsToOrderWithUnits(chosenWeek) {
        let recipesToOrder = [];

        for (let recipe of cookingRecipeList) {
            if (recipe.week === chosenWeek) {
                recipesToOrder.push(recipe);
            }
        }

        let ingredientsToOrder = {};

        for (let recipe of recipesToOrder) {
            for (let recipeIngredient of recipe.ingredients) {
                const ingredientName = recipeIngredient.name.toString();
                const selectedUnit = recipeIngredient.selectedUnit;

                const amountInGrams = recipeIngredient.amount * recipeIngredient.nutrition.weightPerServing.amount;
                if (ingredientsToOrder[ingredientName]) {
                    // If the unit matches the selected unit, add the amounts together
                    if (ingredientsToOrder[ingredientName].unit === selectedUnit) {
                        ingredientsToOrder[ingredientName].amount += recipeIngredient.amount;
                    } else {
                        // If units don't match, convert everything to grams
                        ingredientsToOrder[ingredientName].unit = 'g';
                        ingredientsToOrder[ingredientName].amountInGrams += amountInGrams;
                    }
                } else {
                    if (selectedUnit === 'g') {
                        ingredientsToOrder[ingredientName] = {
                            name: ingredientName,
                            image: recipeIngredient.image,
                            unit: 'g',
                            amount: amountInGrams,
                        };
                    } else {
                        ingredientsToOrder[ingredientName] = {
                            name: ingredientName,
                            unit: selectedUnit,
                            image: recipeIngredient.image,
                            amount: recipeIngredient.amount,
                            amountInGrams: amountInGrams
                        };
                    }
                }
            }
        }

        // Convert the final output to the required format
        let finalIngredientsToOrder = Object.keys(ingredientsToOrder).map(ingredientName => {
            let ingredient = ingredientsToOrder[ingredientName];
            return {
                name: ingredient.name,
                image: ingredient.image,
                unit: ingredient.unit,
                amount:  ingredient.amount
            };
        });

        return finalIngredientsToOrder;
    }


    async function generateAmazonLink(chosenWeek, wholeFoods = false) {
        //orderStarting, orderDays is a range of days to order ingredients for (0,5 is the default which is today to today + 5)
        let ingredientsToOrder = prepareIngredientsToOrder(chosenWeek)
        //{ "ingredients": [{ "name": "penne", "quantityList": [{ "unit": "GRAMS", "amount": 2000 }] }, { "name": "tomato", "quantityList": [{ "unit": "GRAMS", "amount": 10 }] }] }

        let formattedIngredients = { "ingredients": [] }

        for (let ingredientName of Object.keys(ingredientsToOrder)) {
            let ingredientObject = ingredientsToOrder[ingredientName]
            formattedIngredients.ingredients.push({ "name": ingredientObject.name, "quantityList": [{ "unit": "GRAMS", "amount": ingredientObject.amount }] })
        }


        await axios.get('ingredient/amazonFreshUrl', { params: { ingredients: formattedIngredients } }).then(response => {
            setTimeout(() => {
                let urlToOpen = response.data
                if (wholeFoods) {
                    urlToOpen = urlToOpen.replace("QW1hem9uIEZyZXNo", "VUZHIFdob2xlIEZvb2Rz")
                }
                Browser.open({ url: urlToOpen, windowName: '_blank' });

            })
        })


    }

    async function generateInstacartPage(chosenWeek) {
        //orderStarting, orderDays is a range of days to order ingredients for (0,5 is the default which is today to today + 5)
        let ingredientsToOrder = prepareIngredientsToOrder(chosenWeek)
        let formattedIngredients = []
        for (let ingredientName of Object.keys(ingredientsToOrder)) {
            let ingredientObject = ingredientsToOrder[ingredientName]
            if (ingredientObject.amount) {
                formattedIngredients.push(ingredientObject.amount + " " + ingredientObject.name)
            }
            else if (ingredientObject.amountInGrams) {
                formattedIngredients.push(ingredientObject.amountInGrams + "g of " + ingredientObject.name)
            }

        }
        // let formattedIngredientString = formattedIngredients.join(", ")

        axios.post("https://thebitetracker.com/instacart", { ingredients: formattedIngredients, name: currentUser.displayName ? currentUser.displayName + "'s" : "your" }, { headers: { 'accept': 'application/json' } }).then(async res => {
            if (res.data && res.data.id) {
                const id = res.data.id
                // Redirect to the intermediary page with Instacart button
                setTimeout(() => {
                    Browser.open({ url: `https://www.thebitetracker.com/instacart/${id}`, windowName: '_blank' });

                })
                return
            }
            else {
                console.log(res)
                throw new Error("Instacart page failed to load")
            }

        });



    }

    function getMealDateString(meal) {
        if (meal.mealDate) {
            return meal.mealDate; // Already in YYYY-MM-DD format
        }
        if (meal.calendarDate) {
            // Convert legacy calendarDate to YYYY-MM-DD
            const date = new Date(meal.calendarDate);
            const userTimezoneOffset = date.getTimezoneOffset() * 60000;
            const localDate = new Date(date.getTime() - userTimezoneOffset);
            return localDate.toISOString().split('T')[0];
        }
        return null;
    }

    return (
        <CartContext.Provider value={{ cartObject: calendarMealList, favoriteFoodList: favoriteFoodList, cookingRecipeList: cookingRecipeList, loadUsers: loadUsers, deleteMeal: deleteMeal, canMoveMeal: canMoveMeal, togglePinMeal: togglePinMeal, setCartMeal: setCartMeal, addOrMoveMealToColumn: addOrMoveMealToColumn, getMealsForCheckout: getMealsForCheckout, duplicateMeal: duplicateMeal, deleteAllMealsByName: deleteAllMealsByName, deleteSingleMealByName: deleteSingleMealByName, triggerCartStateRefresh: triggerCalendarMealStateRefresh, getMealAttributeWithOptions: getMealAttributeWithOptions, prepareIngredientsToOrder: prepareIngredientsToOrder, prepareIngredientsToOrderWithUnits: prepareIngredientsToOrderWithUnits, generateAmazonLink: generateAmazonLink, generateInstacartPage: generateInstacartPage, resetAllContexts: resetAllContexts, generateDailyMeals: generateDailyMeals, addFavoriteFood: addFavoriteFood, removeFoodFromFavorites: removeFoodFromFavorites, addMealsFromCopy: addMealsFromCopy, addFavoriteCustomLog: addFavoriteCustomLog, addRecipeToCookingList: addRecipeToCookingList, addRecipesToCookingList: addRecipesToCookingList, removeRecipeFromCookingList: removeRecipeFromCookingList, updateCookingRecipe: updateCookingRecipe, checkedIngredients: checkedIngredients, setCheckedIngredients: setCheckedIngredients }}>
            {props.children}
        </CartContext.Provider>

    );
};
