export function getDateMonthYear(date) {
    //takes in a javascript Date object (e.g. new Date())
    return date.getMonth() + 1 + " " + date.getDate() + " " + date.getFullYear()
}

export function parseDateMonthYear(dateString) {
    const [month, day, year] = dateString.split(' ');
    // Create a new Date object using ISO format (YYYY-MM-DD)
    // const isoDateString = `${parts[2]}-${parts[0]}-${parts[1]}`;

    const dateObject = new Date(year, month - 1, day);

    return dateObject
}

export function getDateString(date) {
    const ordinals = ["", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th",
        "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"];
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];


    return date.toLocaleDateString('en-US', { weekday: 'long' }) + " " + months[date.getMonth()] + " " + ordinals[date.getDate()]

}


export const getWeeks = (numWeeks = 5, pastWeeks = 0) => {
    const weeks = [];
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const getDaySuffix = (date) => {
        const dateNumber = date.getDate();
        if (dateNumber >= 11 && dateNumber <= 13) {
            return 'th';
        }
        switch (dateNumber % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const formatDate = (date) => {
        const day = daysOfWeek[date.getDay()];
        const dateNumber = date.getDate();
        const suffix = getDaySuffix(date);
        const month = months[date.getMonth()];
        return `${day} ${dateNumber}${suffix} of ${month}`;
    };

    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);  // Normalize time to midnight

    // Adjust current date to Monday of current week
    let dayOfWeek = currentDate.getDay();
    let diff = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when Sunday
    currentDate.setDate(diff);

    // Get past weeks
    for (let i = pastWeeks; i > 0; i--) {
        let startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() - (i * 7) + 1);

        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        weeks.push({
            label: `${formatDate(startOfWeek)} to ${formatDate(endOfWeek)}`,
            startOfWeek: startOfWeek.toISOString().split('T')[0],
            endOfWeek: endOfWeek.toISOString().split('T')[0]
        });
    }

    // Get future weeks
    for (let i = 0; i < numWeeks; i++) {
        let startOfWeek = new Date(currentDate);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1 + (i * 7));

        let endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6);

        weeks.push({
            label: `${formatDate(startOfWeek)} to ${formatDate(endOfWeek)}`,
            startOfWeek: startOfWeek.toISOString().split('T')[0],
            endOfWeek: endOfWeek.toISOString().split('T')[0]
        });
    }
    
    return weeks;
};

  