import React from 'react';
import { Typography, Button, Box } from '@material-ui/core';
import CircularSlider from 'react-circular-slider-svg';

const HeightSelector = ({ feet, inches, onHeightChange, onClose }) => {
    const [selectedFeet, setSelectedFeet] = React.useState(feet ? parseInt(feet) : 5);
    const [selectedInches, setSelectedInches] = React.useState(inches ? parseInt(inches) : 0);

    const handleSave = () => {
        onHeightChange(selectedFeet.toString(), selectedInches.toString());
        onClose();
    };

    // Prevent touch events from propagating to the drawer
    const preventPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <Box 
            p={2} 
            style={{ 
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                background: '#fff',
                zIndex: 1300
            }}
            onTouchMove={preventPropagation}
            onTouchStart={preventPropagation}
            onTouchEnd={preventPropagation}
        >
            <Typography variant="h6" align="center" gutterBottom>
                Select Height
            </Typography>

            <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                my={1}
                onTouchMove={preventPropagation}
                onTouchStart={preventPropagation}
                onTouchEnd={preventPropagation}
            >
                {/* Feet Dial */}
                <Box position="relative" width="50%" display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="subtitle2" gutterBottom>
                        Feet
                    </Typography>
                    <CircularSlider
                        size={180}
                        minValue={4}
                        maxValue={7}
                        startAngle={45}
                        endAngle={315}
                        value={selectedFeet}
                        onChange={value => setSelectedFeet(Math.round(value))}
                        handleSize={16}
                        handle1={{
                            value: selectedFeet,
                            onChange: value => setSelectedFeet(Math.round(value))
                        }}
                        arcColor="rgb(76, 184, 133)"
                        coerceToInt={true}
                    />
                    <Typography variant="h5" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        {selectedFeet}'
                    </Typography>
                </Box>

                {/* Inches Dial */}
                <Box position="relative" width="50%" display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="subtitle2" gutterBottom>
                        Inches
                    </Typography>
                    <CircularSlider
                        size={180}
                        minValue={0}
                        maxValue={11}
                        startAngle={45}
                        endAngle={315}
                        value={selectedInches}
                        onChange={value => setSelectedInches(Math.round(value))}
                        handleSize={16}
                        handle1={{
                            value: selectedInches,
                            onChange: value => setSelectedInches(Math.round(value))
                        }}
                        arcColor="rgb(76, 184, 133)"
                        coerceToInt={true}
                    />
                    <Typography variant="h5" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        {selectedInches}"
                    </Typography>
                </Box>
            </Box>

            <Box mt={1} display="flex" justifyContent="space-between">
                <Button onClick={onClose}>
                    Cancel
                </Button>
                <Button 
                    color="primary" 
                    onClick={handleSave}
                    style={{ color: 'rgb(76, 184, 133)' }}
                >
                    Save
                </Button>
            </Box>
        </Box>
    );
};

export default HeightSelector;
