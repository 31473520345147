// activity_lifestyle_step.jsx

import React from 'react';
import { Typography } from '@material-ui/core';
import './activity_lifestyle_step.css';


export const lifestyleExamples = {
    1.2: {
        title: "Sedentary",
        description: "Very little movement in daily life (<5,000 steps/day)",
        examples: [
            "Desk job",
            "Driving for most transportation"
        ]
    },
    1.3: {
        title: "Lightly Active",
        description: "Some movement in daily routine (5,000-7,500 steps/day)",
        examples: [
            "Teacher",
            "Retail worker",
            "Walking for some transportation"
        ]
    },
    1.4: {
        title: "Moderately Active",
        description: "Regular movement in daily life (7,500-10,000 steps/day)",
        examples: [
            "Server/Bartender",
            "Store clerk",
            "Active parent with young children"
        ]
    },
    1.5: {
        title: "Very Active",
        description: "Constant movement in daily life (>10,000 steps/day)",
        examples: [
            "Construction worker",
            "Mail carrier",
            "Warehouse worker",
    
        ]
    }
};

export const exerciseFrequencies = [
    { value: 1.0, label: "0-1 days/week", description: "Little to no planned exercise" },
    { value: 1.06, label: "2-3 days/week", description: "Some regular exercise" },
    { value: 1.12, label: "4-5 days/week", description: "Consistent exercise" },
    { value: 1.18, label: "6-7 days/week", description: "Daily exercise" }
];


const ActivityLifestyleStep = ({ formData, onUpdate, onNext, onBack }) => {



    const handleSubmit = (e) => {
        e.preventDefault();
        onNext();
    };

    return (
        <form onSubmit={handleSubmit} className="bt-activity-form">
            <div className="bt-activity-title">
                Tell us about your
                <b style={{ color: 'rgb(45, 121, 51)' }}> activity level</b>
            </div>

            <div className="bt-activity-section">
                <Typography variant="subtitle1" className="bt-activity-section-title">
                    Daily Lifestyle Activity
                </Typography>
                <Typography variant="body2" className="bt-activity-section-subtitle">
                    Select based on your typical day-to-day movement (not including exercise)
                </Typography>

                <div className="bt-activity-options">
                    {Object.entries(lifestyleExamples).map(([level, data]) => (
                        <div 
                            key={level}
                            onClick={() => onUpdate({ activityLevel: parseFloat(level) })}
                            className={`bt-activity-option ${
                                formData.activityLevel === parseFloat(level) ? 'selected' : ''
                            }`}
                        >
                            <div className="bt-activity-option-header">
                                <h3>{data.title}</h3>
                                <span>{data.description}</span>
                            </div>
                            <div className="bt-activity-option-examples">
                                {data.examples.map((example, i) => (
                                    <span key={i} className="bt-activity-example-tag">
                                        {example}
                                    </span>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="bt-activity-section">
                <Typography variant="subtitle1" className="bt-activity-section-title">
                    Cardio Exercise
                </Typography>
                <Typography variant="body2" className="bt-activity-section-subtitle">
                    How often do you do cardio/aerobic exercise?
                </Typography>

                <div className="bt-activity-frequency-options">
                    {exerciseFrequencies.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => onUpdate({ cardioActivity: option.value })}
                            className={`bt-activity-frequency-option ${
                                formData.cardioActivity === option.value ? 'selected' : ''
                            }`}
                        >
                            <div className="bt-activity-frequency-label">{option.label}</div>
                            <div className="bt-activity-frequency-description">
                                {option.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="bt-activity-section">
                <Typography variant="subtitle1" className="bt-activity-section-title">
                    Strength Training
                </Typography>
                <Typography variant="body2" className="bt-activity-section-subtitle">
                    How often do you do resistance/strength training?
                </Typography>

                <div className="bt-activity-frequency-options">
                    {exerciseFrequencies.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => onUpdate({ muscleBuildingActivity: option.value })}
                            className={`bt-activity-frequency-option ${
                                formData.muscleBuildingActivity === option.value ? 'selected' : ''
                            }`}
                        >
                            <div className="bt-activity-frequency-label">{option.label}</div>
                            <div className="bt-activity-frequency-description">
                                {option.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </form>
    );
};

export default ActivityLifestyleStep;