// components/shared/graphs/hydration_over_time_graph.js

import React from 'react';
import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import { groupDataByWeek } from 'helpers/data_helpers';

const HydrationOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject;
    let data = [];

    if (userObject.waterIntake && Object.keys(userObject.waterIntake).length > 0) {
        for (let dateStr of Object.keys(userObject.waterIntake)) {
            const date = parseDateMonthYear(dateStr);
            const intake = userObject.waterIntake[dateStr];

            if (intake > 0) {
                data.push({
                    date: date,
                    intake: intake
                });
            }
        }

        // Group data by week
        const groupedData = groupDataByWeek(data);


        // Aggregate data per week
        let aggregatedData = [];

        for (let weekDate in groupedData) {
            const weekData = groupedData[weekDate];
            const totalIntake = weekData.reduce((sum, item) => sum + item.intake, 0);
            const daysWithData = weekData.length;
            const avgIntake = totalIntake / daysWithData;

            aggregatedData.push({
                weekDate: weekDate,
                avgIntake: avgIntake
            });
        }

        // Modify week labels
        aggregatedData = aggregatedData.map((week) => {
            const date = new Date(week.weekDate);
            
            const formattedDate = date.toLocaleDateString('en-US', { 
                month: 'numeric', 
                day: 'numeric',
                timeZone: 'UTC'
            });
            
            return {
                ...week,
                weekLabel: `Week of ${formattedDate}`,
            };
        });

        // Sort the data by week date
        aggregatedData.sort((a, b) => new Date(a.weekDate) - new Date(b.weekDate));

        return (
            <ResponsiveContainer style={{marginLeft:'-20px'}} width="100%" height={300}>
                <AreaChart data={aggregatedData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip formatter={(value) => `${value.toFixed(1)} cups`} />
                    <Area
                        type="monotone"
                        dataKey="avgIntake"
                        name="Avg Hydration"
                        stroke="#24C581"
                        fill="#82ca9d"
                    />
                    <Brush dataKey="weekLabel" height={30} stroke="#24C581" />
                </AreaChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h2>No hydration data found.</h2>
                <p>Please log your hydration to see data.</p>
            </div>
        );
    }
};

export default HydrationOverTimeGraph;
