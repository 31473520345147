// CalendarColumn.js

import React from 'react';
import CalendarSlot from './calendar_slot';
import CalendarChart from '../calendar_chart/calendar_chart';
import CalendarEmptyColumn from './calendar_empty_column';

import LoopIcon from '@material-ui/icons/Loop';
import { UserContext } from 'contexts/user_context';
import { CartContext } from 'contexts/cart_context';
import { AuthContext } from 'contexts/auth_context';
import CalorieBox from '../modify_calories/calorie_box';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import Popover from '@material-ui/core/Popover';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Dialog } from '@material-ui/core';
import LogWeight from '../log_weight/log_weight';
import WeightOverTimeGraph from '../graphs/weight_over_time_graph';
import { useHistory } from 'react-router-dom';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import WaterTrackerCard from '../water_tracker_card/water_tracker_card';
import ExerciseTrackerCard from '../exercise_tracker_card/exercise_tracker_card';
import StepTrackerCard from '../step_tracker_card/step_tracker_card';

const CalendarColumn = (props) => {
  const getCalorieCategory = React.useContext(UserContext).getCalorieCategory();
  const recipeList = React.useContext(RecipeBooksContext).recipeList;
  const userObject = React.useContext(UserContext).userObject;

  const { currentUser } = React.useContext(AuthContext);
  const [isGenerateLoading, setIsGenerateLoading] = React.useState(false);
  const [wasGenerateTriggered, setWasGenerateTriggered] = React.useState(false);
  const [logWeightDialogOpen, setLogWeightDialogOpen] = React.useState(false);
  const [weightGraphDialogOpen, setWeightGraphDialogOpen] = React.useState(false);
  const history = useHistory();

  // Add activeIndex state
  const [activeIndex, setActiveIndex] = React.useState(0);

  // Define slides and selectedMacros based on activeIndex
  const slides = [
    ['Protein', 'Carbohydrates', 'Fat'],
    ['Sodium', 'Sugar', 'Cholesterol'],
    ['Fiber', 'Net Carbohydrates', 'Saturated Fat'],
  ];

  const selectedMacros = slides[activeIndex];




  function handleLogWeightClick() {
    setLogWeightDialogOpen(true);
  }

  const handleLogWeightExit = () => {
    setLogWeightDialogOpen(false);
  };

  const handleWeightGraphExit = () => {
    setWeightGraphDialogOpen(false);
  };

  const handleLogWeightSubmit = () => {
    setLogWeightDialogOpen(false);
    setWeightGraphDialogOpen(true);
  };


  if (!props.isColumnVisible) {
    return <div className="calendar-column"></div>;
  }

  let categoryMeals = { breakfast: [], lunch: [], dinner: [], snack: [] };
  for (let cartMeal of props.cartMeals) {
    categoryMeals[cartMeal.calendarCategory].push(cartMeal);
  }

  let calendarDateObject = new Date(props.calendarDate);
  let isDayInFuture = true;
  if (new Date() - calendarDateObject > 0) {
    isDayInFuture = false;
  }

  // Calculate totals for macros
  const calculateVerticalTotal = (cartMeals, attribute) => {
    let total = 0;
    for (let meal of cartMeals) {
      if (meal.nutrition) {
        let mealNutrientAmount = getMealNutrientAmount(meal, attribute);
        if (mealNutrientAmount) {
          total += mealNutrientAmount;
        }
      }
    }
    return total;
  };

  const totalCalories = calculateVerticalTotal(props.cartMeals, 'Calories');
  const totalProteins = calculateVerticalTotal(props.cartMeals, 'Protein');
  const totalCarbs = calculateVerticalTotal(props.cartMeals, 'Carbohydrates');
  const totalFats = calculateVerticalTotal(props.cartMeals, 'Fat');
  const totalSodium = calculateVerticalTotal(props.cartMeals, 'Sodium');
  const totalSugar = calculateVerticalTotal(props.cartMeals, 'Sugar');
  const totalCholesterol = calculateVerticalTotal(props.cartMeals, 'Cholesterol');
  const totalFiber = calculateVerticalTotal(props.cartMeals, 'Fiber');
  const totalSaturatedFat = calculateVerticalTotal(props.cartMeals, 'Saturated Fat');
  const totalNetCarbs = calculateVerticalTotal(props.cartMeals, 'Net Carbohydrates');

  let slots = [];
  if (props.cartMeals.length === 0 && !props.isCarouselDisabled) {
    slots.push(<CalendarEmptyColumn key={`calendar-empty`} calendarDate={props.calendarDate} />);
  } else {
    for (let calendarCategory in categoryMeals) {
      if (categoryMeals[calendarCategory].length !== 0) {
        slots.push(
          <CalendarSlot
            key={`calendar-slot-${calendarCategory}`}
            cartMeals={categoryMeals[calendarCategory]}
            calendarDate={props.calendarDate}
            calendarCategory={calendarCategory}
            selectedMacros={selectedMacros} // Pass selectedMacros here
          />
        );
      } else {
        slots.push(
          <CalendarSlot
            key={`calendar-slot-${calendarCategory}`}
            calendarDate={props.calendarDate}
            calendarCategory={calendarCategory}
            selectedMacros={selectedMacros} // Pass selectedMacros here
          />
        );
      }
    }
    slots.push(        <ExerciseTrackerCard calendarDate={props.calendarDate} />
    )
    slots.push(
      !isDayInFuture && (
        <div
          key="log-weight-button"
          style={{
            textAlign: 'center',
            margin: '14px 0px 8px 0px',
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
          }}
        >
          <button className="log-weight-button" onClick={handleLogWeightClick}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <AssessmentOutlinedIcon style={{ marginRight: '5px', marginLeft: '-1px' }} />
              </div>{' '}
              <div>
                <div>Log Weight </div>
                <div style={{ fontSize: '10px', marginTop: '4px' }}>AI Diet Consultation </div>
              </div>
            </div>{' '}
          </button>
        </div>
      )
    );
  }

  return (
    <div className="calendar-column">
      <div style={{ overflowY: 'auto', padding: '0px 10px 0px 10px' }}>
        <CalendarChart
          day={props.day}
          calendarDate={props.calendarDate}
          cartMeals={props.cartMeals}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          userObject={userObject}
          totalProteins={totalProteins}
          totalCarbs={totalCarbs}
          totalFats={totalFats}
          totalSodium={totalSodium}
          totalSugar={totalSugar}
          totalCholesterol={totalCholesterol}
          totalFiber={totalFiber}
          totalNetCarbs={totalNetCarbs}
          totalSaturatedFat={totalSaturatedFat}
          totalCalories={totalCalories}
        />
        <WaterTrackerCard calendarDate={props.calendarDate} />
        <StepTrackerCard calendarDate={props.calendarDate} />
        {slots}

      </div>

     

      <Dialog open={logWeightDialogOpen} maxWidth={'xl'} onClose={handleLogWeightExit}>
        <LogWeight calendarDate={props.calendarDate} handleLogWeightExit={handleLogWeightSubmit} />
      </Dialog>

      <Dialog open={weightGraphDialogOpen} maxWidth={'xl'} onClose={handleWeightGraphExit}>
        <div style={{ width: '350px', maxWidth: 'calc(100vw - 20px)', padding: '10px' }}>
          <div className="input-calories-popover-header" style={{ fontFamily: 'Montserrat-SemiBold' }}>
            Your weight over time
          </div>

          <WeightOverTimeGraph />
          <div
            style={{
              fontSize: '12px',
              color: 'grey',
              textAlign: 'center',
              margin:'15px 5px'
            }}
          >
            We recommend reviewing your macros and progress with our AI consultant weekly
          </div>
          <button
            className="generate-button"
            style={{ margin: '10px auto' }}
            onClick={() => {
              history.push('ai_consultation');
            }}
          >
            Consult with AI
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default CalendarColumn;
