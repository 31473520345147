// components/shared/graphs/calories_burned_over_time_graph.js

import React from 'react';
import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import { groupDataByWeek } from 'helpers/data_helpers';

const CaloriesBurnedOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject;
    let data = [];

    if (userObject.exerciseData && Object.keys(userObject.exerciseData).length > 0) {
        for (let dateStr of Object.keys(userObject.exerciseData)) {
            const date = parseDateMonthYear(dateStr);
            const exercises = userObject.exerciseData[dateStr];

            let totalCaloriesBurned = 0;

            exercises.forEach(exercise => {
                let caloriesBurned = parseFloat(exercise.caloriesBurned);
                if (!isNaN(caloriesBurned)) {
                    totalCaloriesBurned += caloriesBurned;
                }
            });

            data.push({
                date: date,
                totalCaloriesBurned: totalCaloriesBurned
            });
        }

        // Group data by week
        const groupedData = groupDataByWeek(data);

        // Aggregate data per week
        let aggregatedData = [];

        for (let weekDate in groupedData) {
            const weekData = groupedData[weekDate];
            const totalCaloriesBurned = weekData.reduce((sum, item) => sum + item.totalCaloriesBurned, 0);

            aggregatedData.push({
                weekDate: weekDate,  // Store the full date
                totalCaloriesBurned: totalCaloriesBurned
            });
        }

        // Modify week labels
        aggregatedData = aggregatedData.map((week) => {
            const date = new Date(week.weekDate);
            
            // Format Monday's date
            const formattedDate = date.toLocaleDateString('en-US', { 
                month: 'numeric', 
                day: 'numeric',
                timeZone: 'UTC' // Add this to prevent timezone issues
            });
            
            return {
                ...week,
                weekLabel: `Week of ${formattedDate}`,
            };
        });

        // Sort the data by week date
        aggregatedData.sort((a, b) => new Date(a.weekDate) - new Date(b.weekDate));

        return (
            <ResponsiveContainer style={{marginLeft:'-15px'}} width="100%" height={300}>
                <AreaChart data={aggregatedData}>
                    <XAxis dataKey="weekLabel" />
                    <YAxis />
                    <Tooltip formatter={(value) => `${value.toFixed(0)} kcal`} />
                    <Area
                        type="monotone"
                        dataKey="totalCaloriesBurned"
                        name="Calories Burned"
                        stroke="#FF8042"
                        fill="#FFBB28"
                    />
                    <Brush dataKey="weekLabel" height={30} stroke="#FF8042" />
                </AreaChart>
            </ResponsiveContainer>
        );
    } else {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h2>No exercise data found.</h2>
                <p>Please log your exercise to see data.</p>
            </div>
        );
    }
};

export default CaloriesBurnedOverTimeGraph;
