// components/shared/graphs/exercise_points_over_time_graph.js

import React from 'react';
import { UserContext } from 'contexts/user_context';
import { parseDateMonthYear } from 'helpers/date_helpers';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import { groupDataByWeek } from 'helpers/data_helpers';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons'; // Import the icon

const ExercisePointsOverTimeGraph = () => {
    const userObject = React.useContext(UserContext).userObject;
    const [factorInfoDialogOpen, setFactorInfoDialogOpen] = React.useState(false);

    const pointFactors = [
        {
            key: 'sustainedCardioEffort',
            label: 'Sustained Cardio',
            description:
                'Sustained Cardio Effort refers to activities that keep your heart rate elevated over a prolonged period.',
            recommendedPoints: '750 – 1,800',
        },
        {
            key: 'muscleStrain',
            label: 'Muscle Strain',
            description:
                'Muscle Strain represents the effort/load carried by your muscles during physical activity.',
            recommendedPoints: '500 – 1,500',
        },
        {
            key: 'explosiveEffort',
            label: 'Explosive Effort',
            description:
                'Explosive Effort involves quick, intense movements that improve power, speed, and agility.',
            recommendedPoints: '160 – 600',
        },
        {
            key: 'flexibility',
            label: 'Flexibility',
            description:
                'Flexibility measures the ability of your joints and muscles to move through their full range of motion.',
            recommendedPoints: '120 – 700',
        },
    ];

    let data = [];

    if (userObject.exerciseData && Object.keys(userObject.exerciseData).length > 0) {
        for (let dateStr of Object.keys(userObject.exerciseData)) {
            const date = parseDateMonthYear(dateStr);
            const exercises = userObject.exerciseData[dateStr];

            let totalFlexibility = 0;
            let totalSustainedCardioEffort = 0;
            let totalMuscleStrain = 0;
            let totalExplosiveEffort = 0;

            exercises.forEach((exercise) => {
                const points = exercise.points || {};
                totalFlexibility += points.flexibility || 0;
                totalSustainedCardioEffort += points.sustainedCardioEffort || 0;
                totalMuscleStrain += points.muscleStrain || 0;
                totalExplosiveEffort += points.explosiveEffort || 0;
            });

            data.push({
                date: date,
                flexibility: totalFlexibility,
                sustainedCardioEffort: totalSustainedCardioEffort,
                muscleStrain: totalMuscleStrain,
                explosiveEffort: totalExplosiveEffort,
            });
        }

        // Group data by week
        const groupedData = groupDataByWeek(data);

        // Aggregate data per week
        let aggregatedData = [];

        for (let weekDate in groupedData) {
            const weekData = groupedData[weekDate];

            const totalFlexibility = weekData.reduce((sum, item) => sum + item.flexibility, 0);
            const totalSustainedCardioEffort = weekData.reduce(
                (sum, item) => sum + item.sustainedCardioEffort,
                0
            );
            const totalMuscleStrain = weekData.reduce((sum, item) => sum + item.muscleStrain, 0);
            const totalExplosiveEffort = weekData.reduce(
                (sum, item) => sum + item.explosiveEffort,
                0
            );

            aggregatedData.push({
                weekDate: weekDate,  // Store the full date
                flexibility: totalFlexibility,
                sustainedCardioEffort: totalSustainedCardioEffort,
                muscleStrain: totalMuscleStrain,
                explosiveEffort: totalExplosiveEffort,
            });
        }

        // Modify week labels
        aggregatedData = aggregatedData.map((week) => {
            const date = new Date(week.weekDate);
            
            // Format Monday's date
            const formattedDate = date.toLocaleDateString('en-US', { 
                month: 'numeric', 
                day: 'numeric',
                timeZone: 'UTC' // Add this to prevent timezone issues
            });
            
            return {
                ...week,
                weekLabel: `Week of ${formattedDate}`,
            };
        });

        // Sort the data by week date
        aggregatedData.sort((a, b) => new Date(a.weekDate) - new Date(b.weekDate));

        return (
            <div style={{ position: 'relative', width: '100%', marginLeft: '-15px' }}>
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={aggregatedData}>
                        <XAxis dataKey="weekLabel" />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="flexibility"
                            stackId="1"
                            name="Flexibility"
                            stroke="#8884d8"
                            fill="#8884d8"
                        />
                        <Area
                            type="monotone"
                            dataKey="sustainedCardioEffort"
                            stackId="1"
                            name="Sustained Cardio"
                            stroke="#82ca9d"
                            fill="#82ca9d"
                        />
                        <Area
                            type="monotone"
                            dataKey="muscleStrain"
                            stackId="1"
                            name="Muscle Strain"
                            stroke="#ffc658"
                            fill="#ffc658"
                        />
                        <Area
                            type="monotone"
                            dataKey="explosiveEffort"
                            stackId="1"
                            name="Explosive Effort"
                            stroke="#d0ed57"
                            fill="#d0ed57"
                        />
                        <Brush dataKey="weekLabel" height={30} stroke="#8884d8" />
                    </AreaChart>
                </ResponsiveContainer>
                {/* Clickable text to open the dialog */}
                <div
                    style={{
                        marginTop: '10px',
                        fontSize: '12px',
                        color: 'grey',
                        marginLeft: '20px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => setFactorInfoDialogOpen(true)}
                >
                    <HelpOutline style={{ marginRight: '5px', fontSize: '16px', color: 'grey' }} />
                    Learn more about exercise points
                </div>
                {/* Factor Info Dialog */}
                <Dialog
                    open={factorInfoDialogOpen}
                    onClose={() => setFactorInfoDialogOpen(false)}
                    scroll="paper"
                    maxWidth="xl"
                >
                    <DialogTitle>Exercise Points Factors</DialogTitle>
                    <DialogContent dividers>
                        {pointFactors.map((factor) => (
                            <div key={factor.key} style={{ marginBottom: '15px' }}>
                                <Typography variant="subtitle1">{factor.label}</Typography>
                                <Typography variant="body2">{factor.description}</Typography>
                                <Typography
                                    variant="body2"
                                    style={{ marginTop: '5px' }}
                                >
                                    <span style={{ fontFamily: 'Montserrat', color: 'rgb(241, 160, 146)' }}>
                                        Suggested weekly points:{' '}
                                    </span>
                                    <span style={{ fontFamily: 'Montserrat-SemiBold', color: '#414141' }}>
                                        {factor.recommendedPoints}
                                    </span>
                                </Typography>
                            </div>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setFactorInfoDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <h2>No exercise data found.</h2>
                <p>Please log your exercise to see data.</p>
            </div>
        );
    }
};

// Helper function to get the start date of the ISO week
const getDateOfISOWeek = (isoWeekString) => {
    const [yearStr, weekStr] = isoWeekString.split('-W');
    const year = parseInt(yearStr, 10);
    const week = parseInt(weekStr, 10);

    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dayOfWeek = simple.getDay();
    const ISOweekStart = new Date(simple);

    if (dayOfWeek <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }
    return ISOweekStart;
};

export default ExercisePointsOverTimeGraph;
