//React, router
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

//context
import { UserContext } from 'contexts/user_context';
import { Dialog } from '@material-ui/core';
import CalorieCalculator from '../calorie_calculator/calorie_calculator';

const CalorieMealSuggestion = (props) => {

    const history = useHistory();
    const [calculatorDialogOpen, setCalculatorDialogOpen] = React.useState(false)

    function handleOpenCalculatorClick() {
        setCalculatorDialogOpen(true)
    }

    function handleCalculatorDialogExitClick() {
        setCalculatorDialogOpen(false)
    }

    function handleAIConsultationClick() {
        history.push('/ai_consultation');
        props.close()
    }

    if (props.disableRecommendation) {

        return (
            <>
                <div className="calorie-meal-suggestion-calculator-message" style={{ justifySelf: 'auto' }}>Need help with your macros?
                <div style={{ marginTop: '10px'}}><button className="calorie-meal-suggestion-message-button" style={{ marginRight: '7px'}} variant="outlined" onClick={handleOpenCalculatorClick}>Calculator</button>
                <button className="calorie-meal-suggestion-message-button" style={{ marginLeft: '7px'}} variant="outlined" onClick={handleAIConsultationClick}>AI Consultation</button></div></div>
                <Dialog open={calculatorDialogOpen} onClose={handleCalculatorDialogExitClick} >
                    <CalorieCalculator handleExitClick={handleCalculatorDialogExitClick} />
                </Dialog>
            </>

            
        )
    }
    else {
        return <></>
    }
}
export default CalorieMealSuggestion