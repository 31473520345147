import './modify_calories.css';

//React, router
import React from 'react';

//Material UI
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

//context
import { UserContext } from 'contexts/user_context';


import CalorieMealSuggestion from './calorie_meal_suggestion';


const CalorieBox = (props) => {
    const userObject = React.useContext(UserContext).userObject;
    const setUserObject = React.useContext(UserContext).setUserObject;



    const HandleChangeCalories = (e) => {
        let targetFat = Math.round((e.target.value * 0.3) / 9)
        let targetProtein = Math.round((e.target.value * 0.3) / 4)
        let targetCarbs = Math.round((e.target.value * 0.4) / 4)

        setUserObject({ ...userObject, calories: e.target.value, proteins: targetProtein, carbs: targetCarbs, fats: targetFat });

    }

    const HandleChangeMacros = (e) => {
        let calculatedCalories;
        if (e.target.name === "fats") {
            calculatedCalories = e.target.value * 9 + parseInt(userObject.carbs) * 4 + parseInt(userObject.proteins) * 4
        }

        if (e.target.name === "carbs") {
            calculatedCalories = e.target.value * 4 + parseInt(userObject.fats) * 9 + parseInt(userObject.proteins) * 4
        }

        if (e.target.name === "proteins") {
            calculatedCalories = e.target.value * 4 + parseInt(userObject.fats) * 9 + parseInt(userObject.carbs) * 4
        }

        setUserObject({ ...userObject, [e.target.name]: e.target.value, calories: calculatedCalories });

    }



    //user is inputting macros
    return (
        <div className="calorie-box">
            <div className="calorie-box-header">{props.skipped? "Input your daily macros":"Your daily macros"}

            </div>


            <div className="calorie-box-inner">
                <div style={{ display: 'flex', alignItems: 'center' }}><TextField placeholder="1800" className="calorie-box-text-field calories" variant="filled" type="number" name='calories' value={userObject.calories} onChange={HandleChangeCalories}></TextField>
                    <span className="calorie-box-label calories-background">Calories</span></div>

                <div style={{ display: 'flex', alignItems: 'center' }}><TextField placeholder="150" className="calorie-box-text-field protein" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }} type="number" variant="filled" label="" name='proteins' value={userObject.proteins} onChange={HandleChangeMacros}></TextField>
                    <span className="calorie-box-label protein-background">Protein</span></div>

                <div style={{ display: 'flex', alignItems: 'center' }}> <TextField placeholder="180" className="calorie-box-text-field carbs" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }} type="number" variant="filled" label="" name='carbs' value={userObject.carbs} onChange={HandleChangeMacros}></TextField>
                    <span className="calorie-box-label carbs-background">Carbs</span></div>

                <div style={{ display: 'flex', alignItems: 'center' }}><TextField placeholder="60" className="calorie-box-text-field fat" InputProps={{ endAdornment: <InputAdornment position="end">g</InputAdornment>, 'aria-label': 'naked' }} type="number" variant="filled" label="" name='fats' value={userObject.fats} onChange={HandleChangeMacros}></TextField>
                    <span className="calorie-box-label fat-background">Fat</span></div>

            </div>
            <CalorieMealSuggestion disableRecommendation={true} close={props.close} />

        </div>
    );


}
export default CalorieBox