import './filters.css'

import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { config } from 'Constants'
import { UserContext } from 'contexts/user_context';
import { styled } from '@material-ui/core';
import { PrettoSmallSlider } from '../meal_portioning_slider/meal_portioning_slider';
import { ButtonGroup, Button } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';




const Filters = (props) => {

    const StyledToggleButton = styled(ToggleButton)({
        "&.MuiToggleButton-root": {
            color: "#797979",
            background: "#DFDFDF",
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "#4A7018",
            background: "#E8F5E9",
            fontFamily: 'Montserrat-SemiBold',
            boxShadow: '0 2px 4px rgba(94,139,32,0.2)',
        }
    });

    const filters = props.filters;
    const setFilters = props.setFilters
    // const menuCategories = useContext(MenuContext).getMenuCategories()
    // const menuFilters = useContext(MenuContext).getMenuFilters()
    const customRecipeBookId = React.useContext(UserContext).getCustomRecipeBookId()
    const menuCategories = ["breakfast", "lunch", "dinner", "snack"]
    const menuFilters = [
        'side dish',
        'main dish',
        'low-cal',
        'low-fat',
        'low-carb',
        'high-protein',
        'vegetarian',
        'vegan',
        'gluten free',
        'dairy free'
    ];

    const toggleFilteredCategory = (e, newValue) => {
        if (newValue) {
            props.setMealCategoriesFiltered(newValue);
        }
        else {
            props.setMealCategoriesFiltered("");
        }
    }

    const toggleFilteredRecipeBook = (e, newValue) => {
        if (newValue) {
            props.setRecipeBooksFiltered(newValue)
        }
        else {
            props.setRecipeBooksFiltered("")
        }
    }

    const toggleFilter = (e, newValue) => {
        setFilters({ ...filters, [e.currentTarget.value]: !filters[e.currentTarget.value] });
    }
    
    let categoryToggleButtons = []

    for (let menuCategoryIndex in menuCategories) {
        categoryToggleButtons.push(<StyledToggleButton className="meal-picker-filter-button"
            value={menuCategories[menuCategoryIndex]}
            key={"category-" + menuCategoryIndex}
        >
            {menuCategories[menuCategoryIndex]}
        </StyledToggleButton>)

    }

    let filterToggleButtons = []

    for (let menuFilterIndex in menuFilters) {
        filterToggleButtons.push(<StyledToggleButton key={`filter-${menuFilterIndex}`}
            className="meal-picker-filter-button"
            value={menuFilters[menuFilterIndex]}
            selected={filters[menuFilters[menuFilterIndex]]}
            onChange={toggleFilter}>
            {menuFilters[menuFilterIndex]}
        </StyledToggleButton>)

    }

    const TimeRangeButton = styled(Button)({
        "&.MuiButton-root": {
            color: "#616161",
            background: "#F5F5F5",
            textTransform: "none",
            fontSize: "12px",
            padding: "6px 12px",
            minWidth: "90px",
            fontFamily: "Montserrat-SemiBold",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            '&:hover': {
                background: "#EEEEEE",
                transform: 'translateY(-1px)',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            },
        },
        "&.Mui-selected, &.active": {
            color: "#4A7018",
            background: "#E8F5E9",
            boxShadow: '0 2px 4px rgba(94,139,32,0.2)',
        }
    });

    const isTimeRangeSelected = (start, end, timeRanges) => {
        return timeRanges.some(range => range[0] === start && range[1] === end);
    };

    return (<div>



        {!props.removeRecipeBooks && <><div className="filters-heading">Recipe Books</div>
            <div className="meal-picker-primary-filters">

                <ToggleButtonGroup exclusive type="checkbox" value={props.recipeBooksFiltered} onChange={toggleFilteredRecipeBook}>


                    <StyledToggleButton key={`filter-bite-tracker-recipe-book`}
                        className="meal-picker-filter-button"
                        value={config.baseRecipeBookId}
                    >
                        BiteTracker Recipes
                    </StyledToggleButton>
                    <StyledToggleButton key={`filter-my-recipe-book`}
                        className="meal-picker-filter-button"
                        value={customRecipeBookId}
                    >
                        My Recipes
                    </StyledToggleButton>

                </ToggleButtonGroup>
            </div>
        </>}


        <div className="filters-heading" >Recipe Categories</div>
        <div className="meal-picker-primary-filters">

            <ToggleButtonGroup exclusive type="checkbox" value={props.mealCategoriesFiltered} onChange={toggleFilteredCategory}>


                {categoryToggleButtons}

            </ToggleButtonGroup>
        </div>


        <div className="filters-heading" style={{paddingTop:'6px'}}>Recipe Tags</div>
        <div className="meal-picker-secondary-filters">

            {filterToggleButtons}
        </div>
        
        {props.addTime && (
            <>
                <div className="filters-heading" style={{marginTop: '16px'}}>
                    <AccessTimeIcon style={{fontSize: 16, marginRight: 8, verticalAlign: 'text-bottom'}}/>
                    Recipe Time
                </div>
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '8px',
                    marginTop: '8px'
                }}>
                    <TimeRangeButton 
                        className={isTimeRangeSelected(0, 15, props.timeFiltered) ? 'active' : ''}
                        onClick={() => {
                            const isSelected = isTimeRangeSelected(0, 15, props.timeFiltered);
                            const newTimeRanges = isSelected
                                ? props.timeFiltered.filter(range => !(range[0] === 0 && range[1] === 15))
                                : [...props.timeFiltered, [0, 15]];
                            props.setTimeFiltered(newTimeRanges);
                        }}
                    >
                        Quick (0-15m)
                    </TimeRangeButton>
                    <TimeRangeButton
                        className={isTimeRangeSelected(15, 30, props.timeFiltered) ? 'active' : ''}
                        onClick={() => {
                            const isSelected = isTimeRangeSelected(15, 30, props.timeFiltered);
                            const newTimeRanges = isSelected
                                ? props.timeFiltered.filter(range => !(range[0] === 15 && range[1] === 30))
                                : [...props.timeFiltered, [15, 30]];
                            props.setTimeFiltered(newTimeRanges);
                        }}
                    >
                        15-30 mins
                    </TimeRangeButton>
                    <TimeRangeButton
                        className={isTimeRangeSelected(30, 60, props.timeFiltered) ? 'active' : ''}
                        onClick={() => {
                            const isSelected = isTimeRangeSelected(30, 60, props.timeFiltered);
                            const newTimeRanges = isSelected
                                ? props.timeFiltered.filter(range => !(range[0] === 30 && range[1] === 60))
                                : [...props.timeFiltered, [30, 60]];
                            props.setTimeFiltered(newTimeRanges);
                        }}
                    >
                        30-60 mins
                    </TimeRangeButton>
                    <TimeRangeButton
                        className={isTimeRangeSelected(60, 300, props.timeFiltered) ? 'active' : ''}
                        onClick={() => {
                            const isSelected = isTimeRangeSelected(60, 300, props.timeFiltered);
                            const newTimeRanges = isSelected
                                ? props.timeFiltered.filter(range => !(range[0] === 60 && range[1] === 300))
                                : [...props.timeFiltered, [60, 300]];
                            props.setTimeFiltered(newTimeRanges);
                        }}
                    >
                        60+ mins
                    </TimeRangeButton>
                </div>
                {(props.timeFiltered[0] > 0 || props.timeFiltered[1] < 300) && (
                    <Button
                        style={{
                            fontSize: '11px',
                            color: '#616161',
                            marginTop: '8px',
                            textTransform: 'none'
                        }}
                        onClick={() => props.setTimeFiltered([0, 300])}
                    >
                        Clear time filter
                    </Button>
                )}
            </>
        )}

    </div>
    );

}

export default Filters;